import React from "react";
import styled from 'styled-components';
import clsx from 'clsx';
import Participant from "../Participant/Participant";
import useMainParticipant from "../../hooks/useMainParticipant/useMainParticipant";
import useParticipants from "../../hooks/useParticipants/useParticipants";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import useSelectedParticipant from "../VideoProvider/useSelectedParticipant/useSelectedParticipant";
import {isMobileScreenSize} from "src/utils/helpers";

// #videosContainer .videos-wrapper
const Container = styled.aside`
  background: transparent;
  grid-area: 1 / 2 / 1 / 3;
  z-index: 5;

  @media screen and (max-width: 737px), screen and (max-height: 500px) {
    height: 100vh;
    width: 100vw;
    overflow-y: initial;
    overflow-x: auto;
    display: flex;
  }

  .scrollContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .innerScrollContainer {
    min-width: 315px;
    padding: 15px 15px 0 0;

    @media screen and (max-width: 737px), screen and (max-height: 500px) {
      width: 100%;
      height: 100%;
      display: block;
      padding: 0;
      &-flex {
        display: flex;
        justify-content: space-between;
      }
    }
  }
`;

export default function ParticipantList() {
    const {room, horizontablePosition} = useVideoContext();
    const localParticipant = room ? room.localParticipant : null;
    const participants = useParticipants();
    const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
    const mainParticipant = useMainParticipant();

    if (participants.length === 0) return null; // Don't render this component if there are no remote participants.


    return (
        <Container>
            <div className={'scrollContainer'}>
                <div className={clsx('innerScrollContainer', {'innerScrollContainer-flex': horizontablePosition})}>
                    {localParticipant && localParticipant.sid !== mainParticipant.sid && !isMobileScreenSize() && (
                        <Participant
                            participant={localParticipant}
                            isLocalParticipant={true}
                            onClick={() => setSelectedParticipant(localParticipant)}
                        />)}
                    {participants.map(participant => {
                        const isSelected = participant === selectedParticipant && !isMobileScreenSize();
                        if (isSelected) return null;

                        const hideParticipant =
                            participant === mainParticipant && !isSelected && !isMobileScreenSize();

                        return (
                            <Participant
                                key={participant.sid}
                                participant={participant}
                                isSelected={participant === selectedParticipant}
                                onClick={() => setSelectedParticipant(participant)}
                                hideParticipant={hideParticipant}
                            />
                        );
                    })}
                </div>
            </div>
        </Container>
    );
}
