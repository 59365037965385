import React from "react";
import audioOnIcon from "src/assets/img/audio-on.png";
import audioOffIcon from "src/assets/img/audio-off.png";
import {ControlItem} from "./styled";
import {getDataOptions} from "src/utils/helpers";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import useLocalAudioToggle from "../../hooks/useLocalAudioToggle/useLocalAudioToggle";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";

const ReactTooltipStyled1 = styled(ReactTooltip)`
  z-index: 1 !important;
  position: absolute;
  background-color: #444444 !important;
  color: #ffffff !important;
  border-radius: 15px !important;
  padding: 5px 12px !important;
`;

const AudioController = (props) => {
    const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
    const { localTracks } = useVideoContext();
    const hasAudioTrack = localTracks.some(track => track.kind === 'audio');

    return (
        <>
            <ReactTooltipStyled1
                arrowColor="transparent"
                id={'AudioController'}
                place="top"
                effect="solid"
                className="tooltip1"
                multiline={true}
            >
                Microphone
            </ReactTooltipStyled1>
            <ControlItem
                disabled={!hasAudioTrack || props.disabled}
                data-tip data-for={'AudioController'}
                icon={isAudioEnabled ? audioOnIcon : audioOffIcon}
                data-trackable={
                    getDataOptions({
                        view: 'AudioController',
                        type: 'button',
                        label: isAudioEnabled ? 'audioOnIcon' : 'audioOffIcon'
                    })
                }
                onClick={toggleAudioEnabled}
            />
        </>
    )
};

export default AudioController;
