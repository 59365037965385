import types from '../constans';

const initialState = {
    isNeedBack: false,
    showExplanationAfterCreateEvent: false,
    openSidebar: null,
    sidebarType: '',
    sidebarData: null,
    defaultSurvey: [],
    editableSurvey: [],//editable wrapupSurveyQuestions
    editableWrapupSurvey: {},//editable wrapupSurvey
    customWrapupSurvey: {},
    customWrapupSurveys: [],
    coOrganizers: [],
    isLoading: false,
    initialNavItems: [],
    introductionsMadeCount: 0,
    isMenuAdminOpen: false,
    isShowDeleteEventModal: false,
    emailsTemplatesDefault: [],
    eventReminder: {},

    //Overview page
    lastFiveRegistrations: [],
    allEventRegistrationsCount: 0,
    attendingEventRegistrationsCount: 0,
    attendingCheckInEventRegistrationsCount: 0,
    sponsorCount: 0,
    featuresTierLevel: [],
    error: null,
    showWarningCopyPopup: false,
    isShowCopyTemplateKeyModal: false,
    processedEventId: null,
    templateKey: null,
    typeOfActionForWarningCopyPopup: 'copy', //or template
    removedFeatures: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_USER: {
            return {
                ...state,
                sidebarData: {
                    ...state.sidebarData,
                    bubbleUser: {...action.data.user},
                    userEventTags: {...action.data.tags},
                }
            }
        }
        case types.SET_IS_SHOW_DELETE_EVENT_MODAL:
            return {...state, isShowDeleteEventModal: action.flag};
       case types.SET_IS_SHOW_COPY_TEMPLATE_KEY_MODAL:
            return {...state, isShowCopyTemplateKeyModal: action.flag};
       case types.SET_TEMPLATE_KEY:
            return {...state, templateKey: action.key};
        case types.SET_EVENT_REMINDER:
            return {...state, eventReminder: action.payload, isLoading: false};
        case types.SET_IS_MENU_ADMIN_OPEN:
            return {...state, isMenuAdminOpen: action.flag};
        case types.SET_SPONSOR_COUNT:
            return {...state, sponsorCount: action.count};
        case types.SET_ALL_EVENT_REGISTRATIONS_COUNT:
            return {...state, allEventRegistrationsCount: action.count};
        case types.SET_ATTENDING_EVENT_REGISTRATIONS_COUNT:
            return {...state, attendingEventRegistrationsCount: action.count};
        case types.SET_ATTENDING_CHECK_IN_EVENT_REGISTRATIONS_COUNT:
            return {...state, attendingCheckInEventRegistrationsCount: action.count};
        case types.SET_LAST_FIVE_REGISTRATIONS:
            return {...state, lastFiveRegistrations: action.lastFiveRegistrations};
        case types.SET_SHOW_EXPLANATION_AFTER_CREATE_EVENT:
            return {...state, showExplanationAfterCreateEvent: action.flag};
        case types.SET_INITIAL_NAV_ITEMS:
            return {...state, initialNavItems: action.initialNavItems};
        case types.SET_OPEN_SIDEBAR:
            return {
                ...state,
                openSidebar: action.flag,
                sidebarType: action.sidebarType || state.sidebarType,
                sidebarData: action.data || state.sidebarData,
                isNeedBack: action.isNeedBack || state.isNeedBack,
            };
        case types.SET_DEFAULT_SURVEY:
            return {...state, defaultSurvey: action.defaultSurvey};
        case types.SET_EDITABLE_SURVEY:
            return {...state, editableSurvey: action.editableSurvey};
        case types.SET_CO_ORGANIZERS:
            return {...state, coOrganizers: action.coOrganizers};
        case types.SET_CO_ORGANIZERS_LOADING:
            return {...state, isLoading: action.isLoading};
        case types.SET_CUSTOM_WRAPUP_SURVEY:
            return {...state, customWrapupSurvey: action.customWrapupSurvey};
        case types.SET_CUSTOM_WRAPUP_SURVEY_QUESTIONS:
            return {...state, customWrapupSurveyQuestions: action.customWrapupSurveyQuestions};
        case types.SET_EDITABLE_WRAPUP_SURVEY:
            return {...state, editableWrapupSurvey: action.editableWrapupSurvey};
        case types.SET_EMAILS_TEMPLATES_DEFAULT:
            return {...state, isLoading: false, emailsTemplatesDefault: action.payload};
        case types.SET_CUSTOM_WRAPUP_SURVEYS:
            return {...state, customWrapupSurveys: action.customWrapupSurveys};
        case types.SET_INTRODUCTIONS_MADE_COUNT:
            return {...state, introductionsMadeCount: action.introductionsMadeCount};
        case types.UPDATE_CUSTOM_WRAPUP_SURVEY: {
            const updatedCustomWrapupSurveys = state.customWrapupSurveys.map(item => {
                if (item.id !== action.updatedWrapupSurvey.id) {
                    return item;
                }
                return action.updatedWrapupSurvey;
            });
            return {
                ...state, customWrapupSurveys: updatedCustomWrapupSurveys
            };
        }
        case types.CREATE_CUSTOM_WRAPUP_SURVEY: {
            return {
                ...state, customWrapupSurveys: [...state.customWrapupSurveys, action.createdWrapupSurvey]
            };
        }
        case types.SET_FEATURES_TIER_LEVEL: {
            return {
                ...state, featuresTierLevel: action.featuresTierLevel
            }
        }
        case types.SET_SHOW_WARNING_COPY_POPUP: {
            return {
                ...state,
                showWarningCopyPopup: action.showWarningCopyPopup,
                typeOfActionForWarningCopyPopup: action.typeOfActionForWarningCopyPopup,
                removedFeatures: action.removedFeatures,
                processedEventId: action.eventId
            }
        }
        default:
            return state
    }
}
