import React from 'react';
import styled from "styled-components";
import CloseIcon from '../../../icons/CloseIcon';

const Container = styled.div`
      min-height: 56px;
      background: #F4F4F6;
      border-bottom: 1px solid #E4E7E9;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1em;
    
    .text {
      font-weight: bold;
      font-family: Inter, sans-serif;
    }
    
    .closeBackgroundSelection {
      cursor: pointer;
      display: flex;
      background: transparent;
      border: 0;
      padding: 0.4em;
    }
`;

export default function BackgroundSelectionHeader({ onClose }) {
  return (
    <Container>
      <div className={'text'}>Choose a background</div>
      <button className={'closeBackgroundSelection'} onClick={onClose}>
        <CloseIcon />
      </button>
    </Container>
  );
}
