import React from "react";
import BackgroundSelectionHeader from "./BackgroundSelectionHeader/BackgroundSelectionHeader";
import BackgroundThumbnail from "./BackgroundThumbnail/BackgroundThumbnail";
import styled from "styled-components";


const Drawer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 100%;
  background: white;
  z-index: 126;

  .thumbnailContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    overflow-y: auto;
  }
`;

function BackgroundSelectionDialog({isBackgroundSelectionOpen, setIsBackgroundSelectionOpen, backgroundConfig, backgroundSettings, setBackgroundSettings}) {
    const { images, imageNames, eventBackground } = backgroundConfig;

    return (
        Boolean(isBackgroundSelectionOpen) &&
        <Drawer>
            <BackgroundSelectionHeader onClose={() => setIsBackgroundSelectionOpen(false)}/>
            <div className={'thumbnailContainer'}>
                <BackgroundThumbnail
                    thumbnail={'none'}
                    name={'None'}
                    backgroundSettings={backgroundSettings}
                    setBackgroundSettings={setBackgroundSettings}
                />
                <BackgroundThumbnail
                    thumbnail={'blur'}
                    name={'Blur'}
                    backgroundSettings={backgroundSettings}
                    setBackgroundSettings={setBackgroundSettings}
                />
                {eventBackground.image &&
                    <BackgroundThumbnail
                        thumbnail={'event'}
                        name={eventBackground.name}
                        imagePath={eventBackground.image}
                        backgroundSettings={backgroundSettings}
                        setBackgroundSettings={setBackgroundSettings}
                    />
                }
                {images.map((image, index) => (
                    <BackgroundThumbnail
                        thumbnail={'image'}
                        name={imageNames[index]}
                        index={index}
                        imagePath={image}
                        key={image}
                        backgroundSettings={backgroundSettings}
                        setBackgroundSettings={setBackgroundSettings}
                    />
                ))}
            </div>
        </Drawer>
    );
}

export default BackgroundSelectionDialog;
