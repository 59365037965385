import styled from "styled-components";
import {colors} from "src/components/variables"

export const Container = styled.div`
  display: flex;
`;

export const ControlItem = styled.button`
  width: ${({hide}) => hide ? 0 : '40px'};
  height: ${({hide}) => hide ? 0 : '40px'};
  opacity: ${({hide}) => hide ? 0 : 1};
  margin-left: 4px;
  border-radius: 3px;
  background-color: transparent;
  cursor: pointer;
  background-image: ${pr => `url(${pr.icon})`};
  background-size: ${pr => pr.bgSize ? pr.bgSize : '40%'};
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border: 0;
  transition: background-color .2s ease-out, 
    opacity .4s ease-out ${({hide}) => hide ? '' : '.2s'}, 
    width 0s ease-in ${({hide}) => hide ? '.4s' : ''},
    height 0s ease-in ${({hide}) => hide ? '.4s' : ''};
  ${({hide}) => hide ? 'z-index: -10' : ''};
;

  &:hover {
    background-color: rgba(0,0,0,0.7);
  }
  
  &:active, &:focus {
    // box-shadow: 0 0 2px 2px #3a3a3a inset;
    outline: 0;
  }
  &:nth-child(2) {
    margin-left: 0;
  }
`;

export const CallControlItem = styled(ControlItem)`
  background-color: transparent;
  color: red;
  background-image: ${pr => `url(${pr.icon})`};
  background-size: 50%;
  &:hover {
    color: white;
    background-color: red;
    background-image: ${pr => `url(${pr.hoverIcon})`};
  }
  &:disabled {
    cursor: not-allowed;
    background-color: transparent;
    color: red;
  }
`;

export const StopSharingButton = styled.button`
    font-size: 13px;
    padding: 10px;
    height: 35px;
    border-radius: 3px;
    cursor: pointer;
    font-family: Inter, Arial, sans-serif;
    text-shadow: none;
    background-color: ${colors.blue};
    color: #fff;
    border: 0;
`;
