import videoOnIcon from "src/assets/img/video-on.png";
import videoOffIcon from "src/assets/img/video-off.png";
import React, { useCallback, useRef } from 'react';
import {ControlItem} from "./styled";
import {getDataOptions} from "src/utils/helpers";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import useLocalVideoToggle from "../../hooks/useLocalVideoToggle/useLocalVideoToggle";
import useDevices from "../../hooks/useDevices/useDevices";

const ReactTooltipStyled1 = styled(ReactTooltip)`
  z-index: 1 !important;
  position: absolute;
  background-color: #444444 !important;
  color: #ffffff !important;
  border-radius: 15px !important;
  padding: 5px 12px !important;
`;

const VideoController = (props) => {
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
    const lastClickTimeRef = useRef(0);
    const { hasVideoInputDevices } = useDevices();

    const toggleVideo = useCallback(() => {
        if (Date.now() - lastClickTimeRef.current > 500) {
            lastClickTimeRef.current = Date.now();
            toggleVideoEnabled();
        }
    }, [toggleVideoEnabled]);

    return (
        <>
            <ReactTooltipStyled1
                arrowColor="transparent"
                id={'VideoController'}
                place="top"
                effect="solid"
                className="tooltip1"
                multiline={true}
            >
                Camera
            </ReactTooltipStyled1>
            <ControlItem
                disabled={!hasVideoInputDevices || props.disabled}
                data-tip data-for={'VideoController'}
                icon={isVideoEnabled? videoOnIcon : videoOffIcon}
                data-trackable={
                    getDataOptions({
                        view: 'VideoController',
                        type: 'button',
                        label: isVideoEnabled ? 'videoOnIcon' : 'videoOffIcon'
                    })
                }
                onClick={toggleVideo}
            />
        </>
    )
};

export default VideoController;
