import types from '../constans';

const initialState = {
    availableEvents: [],
    error: null,
    eventLoading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_EVENTS_LOADING:
            return {
                ...state,
                eventLoading: true
            }
        case types.SET_EVENTS_DATA:
            return {
                ...state,
                availableEvents: action.payload,
                eventLoading: false
            };
        case types.SET_EVENT_ERROR_RESPONSE:
            return {
                ...state,
                error: action.payload,
                eventLoading: false
            };
        default:
            return state
    }
}
