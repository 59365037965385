import { useEffect, useState } from 'react';

import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import {errorReasons} from "../../../../utils/constants";
import store from "../../../../store";
import { sendTwilioStatistics, showErrorInfoBlock } from "../../../../store/twilio/actions";
import { setErrorInfoBlockVisibleAction } from "../../../../store/controller/actions";

const useParticipantNetworkQualityLevel = (participant) => {
  const { room } = useVideoContext();
  const localParticipant = room.localParticipant;
  const isLocal = localParticipant === participant;
  const [networkQualityLevel, setNetworkQualityLevel] = useState(participant.networkQualityLevel);
  const [networkQualityLevelData, setNetworkQualityLevelData] = useState([]);

  useEffect(() => {
    const handleNewtorkQualityLevelChange = (newNetworkQualityLevel) => {
      if (isLocal) console.info('[Hooks] participant.on(\'networkQualityLevelChanged\')', {newNetworkQualityLevel});
      return setNetworkQualityLevel(newNetworkQualityLevel);
    };

    setNetworkQualityLevel(participant.networkQualityLevel);
    participant.on('networkQualityLevelChanged', handleNewtorkQualityLevelChange);
    return () => {
      participant.off('networkQualityLevelChanged', handleNewtorkQualityLevelChange);
    };
  }, [participant, isLocal]);

  useEffect(() => {
    if (isLocal && networkQualityLevel !== null) {
      const data = {
        identity: participant.identity,
        date: Date.now(),
        networkQualityLevel
      };

      if (networkQualityLevel === 0) {
        store.dispatch(sendTwilioStatistics({
          networkQualityLevelData: data,
          method: 'FE useParticipantNetworkQualityLevel',
          message: 'NetworkQualityLevel is equal to 0',
        }, 'Network Quality Level'));
      }

      setNetworkQualityLevelData([
          ...networkQualityLevelData, data
      ]);
    }
  }, [networkQualityLevel, isLocal]);

  useEffect(() => {
    const firstData = networkQualityLevelData[0];
    if (firstData && ((Date.now() - firstData.date) > 10000)) {
      const sum = networkQualityLevelData.reduce((accumulator, {networkQualityLevel}) => (
        accumulator + networkQualityLevel
      ), 0);
      const averageValue = sum / networkQualityLevelData.length;

      console.log('Test Network Quality: ', {networkQualityLevelData, sum, averageValue});
      const {controller: {errorInfoBlock, errorInfoBlockReasons}} = store.getState();

      // Doc: https://www.twilio.com/docs/video/using-network-quality-api
      if (averageValue <= 2) {
        store.dispatch(showErrorInfoBlock(errorReasons["REASON_NETWORK_UNSTABLE"]));
        store.dispatch(sendTwilioStatistics({
          averageValue, networkQualityLevelData,
          method: 'FE useParticipantNetworkQualityLevel',
          message: 'Your internet connection is unstable.',
        }, 'Network Quality Level'));
      } else if (errorInfoBlock && errorInfoBlockReasons && errorInfoBlockReasons.networkWarning) {
        store.dispatch(setErrorInfoBlockVisibleAction(false, {
          ...errorInfoBlockReasons, networkWarning: false
        }));
      }
      setNetworkQualityLevelData([]);
    }

  }, [networkQualityLevelData]);

  return networkQualityLevel;
};

export default useParticipantNetworkQualityLevel;
