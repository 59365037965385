import React, { createContext, useCallback, useEffect, useRef, useState } from "react";
import { Client } from "@twilio/conversations";
import moment from "moment";

export const ChatContext = createContext(null);

export const ChatProvider = ({ children }) => {
  const isChatWindowOpenRef = useRef(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [conversation, setConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [chatClient, setChatClient] = useState();
  const [videoRoomSid, setVideoRoomSid] = useState("");

    const connect = useCallback(
        (token, roomSid) => {
            setVideoRoomSid(roomSid);
            const client = new Client(token);

            const handleClientInitialized = (state) => {
                if (state === "initialized") {
                    // @ts-ignore
                    window.chatClient = client;
                    setChatClient(client);
                } else if (state === "failed") {
                    console.error("There was a problem connecting to Twilio's conversation service.");
                }
            };

            client.on("stateChanged", handleClientInitialized);

            return () => {
                client.off("stateChanged", handleClientInitialized);
            };
        },
        []
    );

  useEffect(() => {
    if (conversation) {
      const handleMessageAdded = (message) => {
        console.info('[Hooks] conversation.on(\'messageAdded\', handleMessageAdded);', {message});
        setIsChatWindowOpen(true);
        return setMessages(oldMessages => [...oldMessages, message]);
      };
      conversation.getMessages().then(newMessages => setMessages(newMessages.items));
      conversation.on('messageAdded', handleMessageAdded);
      return () => {
        conversation.off('messageAdded', handleMessageAdded);
      };
    }
  }, [conversation]);

  useEffect(() => {
    // If the chat window is closed and there are new messages, set hasUnreadMessages to true
    const lastMessageTimeFromStorage = localStorage.getItem('chat-viewed');

    if (messages.length && (!lastMessageTimeFromStorage || moment(lastMessageTimeFromStorage).isBefore(moment(moment(messages[messages.length - 1].dateCreated))))) {
      setHasUnreadMessages(true);
    }
  }, [messages]);

  useEffect(() => {
    isChatWindowOpenRef.current = isChatWindowOpen;
    if (isChatWindowOpen) setHasUnreadMessages(false);
  }, [isChatWindowOpen]);

  useEffect(() => {
    if (videoRoomSid && chatClient) {
      chatClient
        .getConversationByUniqueName(videoRoomSid)
        .then(newConversation => {
          //@ts-ignore
          window.chatConversation = newConversation;
          setConversation(newConversation);
        })
        .catch(e => {
          console.error({e});
        });
    }
  }, [videoRoomSid, chatClient]);

    const disconnect = useCallback(() => {
        if(chatClient) chatClient.shutdown();
        setChatClient(undefined);
    }, [chatClient]);

  return (
      <ChatContext.Provider
          value={{
            isChatWindowOpen,
            setIsChatWindowOpen,
            connect,
            disconnect,
            hasUnreadMessages,
            setHasUnreadMessages,
            messages,
            conversation
          }}
      >
        {children}
      </ChatContext.Provider>
  );
};
