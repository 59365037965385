import { useCallback, useEffect, useState } from 'react';

import { SELECTED_AUDIO_OUTPUT_KEY } from '../../constants';
import useDevices from '../../hooks/useDevices/useDevices';
import store from "../../../../store";
import { setAudioOutputId } from "../../../../store/twilio/actions";

export default function useActiveSinkId() {
  const { audioOutputDevices } = useDevices();
  const [activeSinkId, _setActiveSinkId] = useState('default');

  const setActiveSinkId = useCallback((sinkId) => {
    window.localStorage.setItem(SELECTED_AUDIO_OUTPUT_KEY, sinkId);
    _setActiveSinkId(sinkId);
    store.dispatch(setAudioOutputId(sinkId));
  }, []);

  useEffect(() => {
    const selectedSinkId = window.localStorage.getItem(SELECTED_AUDIO_OUTPUT_KEY);
    const hasSelectedAudioOutputDevice = audioOutputDevices.some(
      device => selectedSinkId && device.deviceId === selectedSinkId
    );
    if (hasSelectedAudioOutputDevice && selectedSinkId) {
      _setActiveSinkId(selectedSinkId);
      store.dispatch(setAudioOutputId(selectedSinkId));
    }
  }, [audioOutputDevices]);

  return [activeSinkId, setActiveSinkId];
}
