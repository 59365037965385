import React from "react";
import styled from "styled-components";

import ChatWindowComponent from "../ChatWindow/ChatWindowComponent";
import ParticipantList from "../ParticipantList/ParticipantList";
import { ParticipantAudioTracks } from "../ParticipantAudioTracks/ParticipantAudioTracks";
import MainParticipant from "../MainParticipant/MainParticipant";
import BackgroundSelectionDialog from "../BackgroundSelectionDialog/BackgroundSelectionDialog";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";

const totalMobileSidebarHeight = 90 + 8 * 2 + 2;

const Container = styled.div`
  position: relative;
  height: 100%;
  display: grid;

  @media screen and (max-width: 737px), screen and (max-height: 500px) {
    grid-template-columns: 1fr;
    grid-template-rows: calc(100% - ${totalMobileSidebarHeight}px);
  }
`;

export default function Room() {
    const {isBackgroundSelectionOpen, setIsBackgroundSelectionOpen, backgroundConfig,  backgroundSettings, setBackgroundSettings} = useVideoContext();
    return (
        <Container>
            <MainParticipant/>
            <ParticipantList/>
            <ParticipantAudioTracks />
            <ChatWindowComponent />
            <BackgroundSelectionDialog {...{
                isBackgroundSelectionOpen, setIsBackgroundSelectionOpen,
                backgroundConfig, backgroundSettings, setBackgroundSettings
            }}/>
        </Container>
    );
}
