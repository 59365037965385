import { isMobile, removeUndefineds } from "..";
import store from "../../../../store";
import { P2P_MOBILE_VIDEO_CONSTRAINTS, P2P_VIDEO_CONSTRAINTS } from "../../constants";

export default function useConnectionOptions() {
  const {roomType} = store.getState().twilio;
  if (roomType === 'peer-to-peer') {
    // For P2P:
    return {
      audio: true,
      video: isMobile ? P2P_MOBILE_VIDEO_CONSTRAINTS : P2P_VIDEO_CONSTRAINTS,
      maxAudioBitrate: 16000,

      // Disabling simulcast in P2P rooms should significantly improve the video quality:
      preferredVideoCodecs: [{ codec: 'VP8', simulcast: false }],
    };
  }

  // See: https://sdk.twilio.com/js/video/releases/2.0.0/docs/global.html#ConnectOptions
  // for available connection options.
  const connectionOptions = {
    // Bandwidth Profile, Dominant Speaker, and Network Quality
    // features are only available in Small Group or Group Rooms.
    // Please set "Room Type" to "Group" or "Small Group" in your
    // Twilio Console: https://www.twilio.com/console/video/configure
    bandwidthProfile: {
      video: {
        mode: 'collaboration',
        trackSwitchOffMode: 'detected',
        dominantSpeakerPriority: 'standard',
        contentPreferencesMode: 'auto',
        clientTrackSwitchOffControl: 'manual',
      },
    },
    dominantSpeaker: true,
    networkQuality: { local: 1, remote: 1 },

    // Comment this line if you are playing music.
    maxAudioBitrate: 16000,

    // VP8 simulcast enables the media server in a Small Group or Group Room
    // to adapt your encoded video quality for each RemoteParticipant based on
    // their individual bandwidth constraints. Simulcast should be disabled if
    // you are using Peer-to-Peer or 'Go' Rooms.
    preferredVideoCodecs: [{ codec: 'VP8', simulcast: roomType !== 'peer-to-peer' && roomType !== 'go' }],
  };

  // For mobile browsers, limit the maximum incoming video bitrate to 2.5 Mbps.
  if (isMobile && connectionOptions && connectionOptions.bandwidthProfile && connectionOptions.bandwidthProfile.video) {
    connectionOptions.bandwidthProfile.video.maxSubscriptionBitrate = 2500000;
  }

  // Here we remove any 'undefined' values. The twilio-video SDK will only use defaults
  // when no value is passed for an option. It will throw an error when 'undefined' is passed.
  return removeUndefineds(connectionOptions);
}
