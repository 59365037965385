import React from "react";
import styled from 'styled-components';
import { getSupportFaqsAction } from 'src/store/test/actions'
import {connect} from 'react-redux'
import {getDataOptions} from "../../utils/helpers";

const StyledMessage = styled.span`
  margin-top: 20px;
`;

const Button = styled.button`
    display: block;
    margin: 50px auto 0;
    color: #fff;
    background-color: #5bc0de;
    border: 1px solid #46b8da;
    font-size: 14px;
    padding: 6px 12px;
    
    &:hover {
        cursor: pointer;
    }
`;


class AccessGrantedErrorBlock extends React.Component {
    render () {
        return (
            <p>
                {(this.props.reasons.mediaAccessDenied && !this.props.showSupport) && (
                    <StyledMessage>
                        {this.props.currentEvent.micOnly ? 'Please allow access to your microphone.' : 'Please allow access to your webcam and mic.'}
                        <span className="support_link"
                              data-trackable={
                                  getDataOptions({
                                      view: 'AccessGrantedErrorBlock',
                                      type: 'button',
                                      label: 'Show me how to fix this.'
                                  })
                              }
                              onClick={() => {this.props.getSupportFaqsAction(1)}}>
                            Show me how to fix this.
                        </span>
                    </StyledMessage>
                )}
                {this.props.reasons.webcamBlocked && (
                    <StyledMessage>
                        Your webcam settings are tied to another application. <br />
                        Please close other browsers or webcam applications (Skype, Zoom, etc.) that are using your webcam.
                    </StyledMessage>
                )}

                <Button
                    onClick={() => {window.location.reload()}}
                    data-trackable={
                        getDataOptions({
                            view: 'AccessGrantedErrorBlock',
                            type: 'button',
                            label: 'Reload'
                        })
                    }
                >Reload</Button>
            </p>
        )
    }
}

export default connect(
    store => ({
        currentEvent: store.controller.currentEvent,
        showSupport: store.test.showSupport,
    }),
    {
        getSupportFaqsAction
    }
)(AccessGrantedErrorBlock)
