import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import CallEndIcon from "src/assets/img/call-end-red.png";
import constants from "src/store/constans";
import { endCallAction, partnerClickedEndCallAction, setEndCallClickedAction } from "src/store/room/actions";
import { getDataOptions } from "src/utils/helpers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { partnerConnectedAction } from "../../../../store/room/actions";
import { setDisconnectedAction, switchFishbowlViewAction } from "../../../../store/controller/actions";
import IndependentGreenButton from "../../../Common/IndependentGreenButton";

export const EndCallButton = styled(IndependentGreenButton)`
  position: relative;
  left: -70px;
  text-align: left;
  margin-top: 0;
  display: block;
  height: 40px;
  width: fit-content;
  font-size: 16px;
  color: #CCCCCC;
  padding: 0 12px;
  line-height: 1;
  border-radius: 3px;
  background-color: #A32722;
  border: 1px solid #A32722;
  
  &:hover {
    color: #ffffff;
    background-color: #821F1B;
    border-color: #821F1B;
  }
  &:focus {
    color: #ffffff;
    background-color: #611714;
    border-color: #611714;
  }
  &:active {
    color: #ffffff;
    background-color: #611714;
    border-color: #611714;
  }
  &:disabled {
    background-color: #b3b3b3;
    border-color: #b3b3b3;
    cursor: not-allowed;
  }
`;

const CallController = props => {
    const { room } = useVideoContext();

    const endCall = () => {
        room.disconnect();

        props.setEndCallClickedAction(true);
        props.partnerClickedEndCallAction();
        props.endCallAction();

        window.sessionStorage.setItem(`clicked-end-call`, props.currentSession.roomNumber);

        if (props.appMode === constants.MEETING_MODE) {
            window.parent.postMessage({ backToSource: true }, '*');
        }
    };

    if (props.showNextPartnerBioPopup) return null;

    return (
        <EndCallButton
            data-trackable={
                getDataOptions({
                    view: 'CallController',
                    type: 'button',
                    label: 'CallEndIcon'
                })
            }
            onClick={endCall}
        >
            <FontAwesomeIcon icon='sign-out-alt' style={{ fontSize: 15 }}/>
            &nbsp;End Call
        </EndCallButton>
    )
};

export default connect(
    store => ({
        currentSession: store.controller.currentSession,
        appMode: store.controller.appMode,
        showNextPartnerBioPopup: store.controller.showNextPartnerBioPopup,
        eventId: store.controller.currentEvent.eventId,
    }),
    {
        setEndCallClickedAction,
        endCallAction,
        partnerClickedEndCallAction,
        partnerConnectedAction,
        setDisconnectedAction,
        switchFishbowlViewAction
    }
)(CallController)
