import types from '../constans';

const initialState = {
    pages: [],
    roles: [],
    permissions: [],
    oktaAuthInstance: null,
    isLoading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_ROLES:
            return {...state, roles: action.roles};
        case types.SET_PAGES:
            return {...state, pages: action.pages};
        case types.SET_PERMISSIONS:
            return {...state, permissions: action.permissions};
        case types.ADD_PAGE:
            return {
                ...state,
                pages: [...state.pages, action.page]
            };
        case types.REMOVE_PAGE:
            return {
                ...state,
                pages: state.pages.filter(page => page.id !== action.pageId),
                permissions: state.permissions.filter(permission => permission.page_id !== action.pageId)
            };
        case types.ADD_ROLE:
            return {
                ...state,
                roles: [...state.roles, action.role]
            };
        case types.REMOVE_ROLE:
            return {
                ...state,
                roles: state.roles.filter(role => role.id !== action.roleId),
                permissions: state.permissions.filter(permission => permission.role_id !== action.roleId)
            };
        case types.ADD_PERMISSIONS:
            return {
                ...state,
                permissions: [...state.permissions, ...action.permissions]
            };
        case types.SET_PAGES_ROLES_PERMISSIONS:
            return {
                ...state,
                pages: action.payload.pages,
                roles: action.payload.roles,
                permissions: action.payload.permissions
            };

        case types.UPDATE_PERMISSION:
            return {
                ...state,
                permissions: state.permissions.map(permission => {
                    if (permission.page_id === action.payload.pageId && permission.role_id === action.payload.roleId)
                        return {...permission, allowed: !permission.allowed}
                    return permission;
                })
            };

        case types.SET_LOADING:
            return {...state, isLoading: action.isLoading};

        case types.SET_OKTA_AUTH_INSTANCE:
            return {...state, oktaAuthInstance: action.oktaAuth};

        default:
            return state
    }
}
