import React from 'react';
import Settings from './Settings'
import {connect} from "react-redux";
import styled from 'styled-components'

const SettingsLayout = styled.div`
  display: ${pr => pr.visible ? 'block' : 'none'};
  position: fixed;
  bottom: 65px;
  right: 0px;
  z-index: 999;
`;

const CurrentVisibleControl = (props) => {
  const current = props.currentDisplayedControl;
  return (
    <SettingsLayout visible={current === 'settings'}>
      <Settings />
    </SettingsLayout>
  );
};

export default connect(
  store => ({
    currentDisplayedControl: store.room.controls.currentDisplayedControl
  })
)(CurrentVisibleControl);
