import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import constants from "src/store/constans";
import _ from "lodash";
import { getProfileColor, getSyncDateNow, getUserCompanyInfo, getUserInitials, getUserName } from "../../utils/helpers";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setPreviousUsersAction } from "src/store/users/actions";
import { reInitRoomController, setProfileView, setSurveySuccessfullyPostedAction } from "src/store/controller/actions";
import { joinIsClickedAction, setPublisherIsReadyAction } from "src/store/room/actions";
import GreenButton from "../Common/GreenButton";
import GrayButton from "../Common/GrayButton";
import CloseXButton from "../Common/CloseXButton";


const ContainerWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: 430px;
  max-width: 100%;
  height: 80%;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 100;

  ${pr => pr.hideNextPartnerBioPopup ? `animation: .6s linear 0s hideModal` : ''};

  @media (max-width: 890px) {
    top: calc(50% - 200px);
  }
  @media (max-height: 500px) {
    top: 110px;
  }

  @keyframes hideModal {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const Container = styled.div`
  width: 430px;
  height: auto;
  margin-top: -80px;
  background-color: #fff;
  border-radius: 10px;
  z-index: 1000;
  opacity: 1;
  animation: .2s linear 0s slide;

  @media (max-height: 500px) {
    min-height: auto;
  }

  @keyframes slide {
    from {
      opacity: 0;
    }
  }
  
  @media (max-width: 890px) {
    max-width: 100%;
    position: relative;
  }
`;

const Main = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  opacity: 1;
  animation: .2s linear 0s slide;
  
  &:last-child {
    margin-right: 0;
  }

  &:hover {
    box-shadow: rgb(204 204 204) 0 2px 6px 0;
    .name {
      color: #0f92c2;
      text-decoration: underline;
    }
  }

  @keyframes slide {
    from {
      opacity: 0;
    }
  }

  @media (max-width: 890px) {
    position: absolute;
    top: 0;
    margin-right: 0;
    min-width: 100%;
    min-height: 100%;
    border-top: 0;
    border-radius: 10px;
  }

  @media (max-height: 500px) {
    max-height: 217px;
  }
`;

const PartnersInfoBlock = styled.div`
  width: 100%;
  min-height: 258px;
  padding: 15px;

  @media (max-height: 500px) {
    min-height: auto;
    max-height: 160px;
  }
`;

const PartnersInfoWrapper = styled.div`
  position: relative;

  @media (max-height: 500px) {
    margin-top: -20px;
  }
`;

const ProfilePicturesBlock = styled.div`
  display: flex;
  margin: 0 auto;
  width: ${({isThreeWayCall}) => isThreeWayCall ? '157px' : '105px'};
`;

const ProfilePicture = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 105px;
  height: 105px;
  min-width: 105px;
  min-height: 105px;
  background-color: white;
  left: calc(50% - 50px);
  border-radius: 50%;

  @media (max-width: 890px) {
    border: 1px solid lightgray;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
  }
`;

const UserInitials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pr => pr.size}px;
  height: ${pr => pr.size}px;
  border-radius: 50%;
  border: 2px solid lightgray;
  background-color: ${pr => pr.bgColor ? pr.bgColor : '#777'};
  color: #fff;
  font-weight: bold;
  font-size: ${pr => pr.fontSize}px;
`;

const UserPhoto = styled.div`
  width: ${pr => pr.size}px;
  height: ${pr => pr.size}px;
  border-radius: 50%;
  background-image: url(${pr => pr.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const UserInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 53px;
  width: 100%;
  padding-top: ${({isShowFullInfo}) => isShowFullInfo ? '5px' : '15px'};
  word-break: break-word;

  .info_username {
    width: 100%;
    display: flex;
    font-weight: bold;
    font-size: 22px;
    
    .separator {
      width: 26px;
    }
    .user_name {
      width: ${({isThreeWayCall}) => isThreeWayCall ? 'calc(50% - 8px)' : '100%'};
    }
  }

  .info_company {
    font-size: 15px;
    text-align: center;
  }

  .info_location {
    display: flex;
    justify-content: center;
    position: relative;
    font-size: 15px;
    color: #b1b1b1;
    padding-left: 15px;
  }

  @media (max-height: 500px) {
    max-height: initial;
    padding-top: 5px;
    
    .additional_user_info {
      display: none;
    }
  }
`;

const LocationIconWrapper = styled.div``;

const FakeBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100vh;
  background-color: rgba(34, 34, 34, 1);

  .circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.4);
    animation: animate 25s linear infinite;
    bottom: -150px;
  }
  .circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }
  .circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }
  .circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }
  .circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }
  .circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }
  .circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }
  .circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
  }
  .circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }
  .circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
  }
  .circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
  }

  @keyframes animate {
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
    }

    100% {
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
    }
  }
`;

const RoundInfoBlock = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 600;
  
  span {
    color: gray;
  }
`;

const TopBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 100px;
  background-color: lightgray;

  @media (max-height: 500px) {
    height: 80px;
  }
`;

const Tooltip = styled.div`
  cursor: auto;
  position: absolute;
  left: 0;
  top: -60px;
  z-index: 2;
  padding: 15px;
  color: black;
  font-weight: normal;
  background-color: #feff99;
  width: 430px;
  max-width: 100%;
  border-radius: 5px;
  
  &:after {
    opacity: ${pr => pr.displayArrow ? 1 : 0};;
    content: "";
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 25px solid #fdff99;
    bottom: -24px;
    position: absolute;
    right: 63px;
  }
`;

const ProfileBtnBlock = styled.div`
  width: 100%;
  min-width: 100%;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const OpenProfileButton = styled(GreenButton)`
  padding: 10px 0;
  width: 80%;
  font-size: 16px;
  font-weight: normal;
  border-radius: 5px;
  margin: 0 auto;
  display: block;

  @media (max-width: 890px) {
    min-width: 95%;
    width: 95%;
  }
  @media (max-height: 500px) {
    margin-top: 8px;
    border-radius: 0 0 10px 10px;
    min-width: 100%;
    width: 100%;
  }
`;

const HiddeProfileButton = styled(GrayButton)`
  padding: 10px 0;
  width: 80%;
  font-size: 16px;
  font-weight: normal;
  border-radius: 5px;
  margin: 0 auto;
  display: block;

  @media (max-width: 890px) {
    min-width: 95%;
    width: 95%;
  }
  @media (max-height: 500px) {
    margin-top: 8px;
    border-radius: 0 0 10px 10px;
    min-width: 100%;
    width: 100%;
  }
`;

const MutualRequestToMeet = styled.div`
  position: absolute;
  bottom: -30px;
  color: #bfbfbf;
  font-size: 15px;
`;

const PartnersInfo = ({
                          nextUsers, sessionFormat, getProfilePhoto, isThreeWayCall,
                          sessionDurationMins, numberOfSessions, sessionNumber,
}) => {
    const firstUser = nextUsers[0];
    const secondUser = nextUsers.length > 1 ? nextUsers[1] : null;

    const showCompanyInfo = () => {
        return sessionFormat !== constants.SESSION_FORMAT_1TO1;
    };

    return (
        <PartnersInfoBlock>
            <RoundInfoBlock>
                <div>Round {sessionNumber} <span>of {numberOfSessions}</span></div>
                <div>
                    {sessionDurationMins} mins
                </div>
            </RoundInfoBlock>
            <TopBackground />
            <PartnersInfoWrapper>
                <ProfilePicturesBlock isThreeWayCall={isThreeWayCall}>
                    {firstUser &&
                        <ProfilePicture>
                            {getProfilePhoto(firstUser)}
                        </ProfilePicture>
                    }
                    {secondUser &&
                        <ProfilePicture style={{marginLeft: -52.5}}>
                            {getProfilePhoto(secondUser)}
                        </ProfilePicture>
                    }
                </ProfilePicturesBlock>

                <UserInfoBlock isThreeWayCall={isThreeWayCall}>
                    <div className={'info_username'}>
                        {firstUser &&
                            <div className={'user_name'} style={{textAlign: isThreeWayCall ? 'right' : 'center'}}>
                                {getUserName(firstUser, isThreeWayCall)}
                            </div>
                        }
                        {secondUser &&
                            <>
                                <div className={'separator'}>&nbsp;<span style={{ color: "lightgrey" }}>&</span>&nbsp;</div>
                                <div className={'user_name'}>{getUserName(secondUser, isThreeWayCall)}</div>
                            </>
                        }
                    </div>

                    {!secondUser && firstUser &&
                        <div className={'additional_user_info'}>
                            {showCompanyInfo() && (
                                <div className={'info_company'}> {getUserCompanyInfo(firstUser)} </div>)}
                            {!!firstUser.location && (
                                <div className={'info_location'}>
                                    <LocationIconWrapper>
                                        <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                        &nbsp;{firstUser.location}
                                    </LocationIconWrapper>
                                </div>
                            )}
                        </div>
                    }
                </UserInfoBlock>
            </PartnersInfoWrapper>
        </PartnersInfoBlock>
    )
};

const NextPartnerBio = ({
                            previousUsers, setPublisherIsReadyAction,
                            setSurveySuccessfullyPostedAction, reInitRoomController,
                            otherUser, otherUserB, hideNextPartnerBioPopup,
                            currentSession: {sessionStartTime, sessionEndTime, sessionNumber, roomNumber, styleMatch},
                            currentEvent: { sessionFormat, numberOfSessions, minPerSession, eventId },
                            isDisplayProfile, setProfileView, eventRegistrations, currentUser,
                            isPreviewMode = false
}) => {
    const [showTooltip, setShowTooltip] = useState(true);
    const [reconnected, setReconnected] = useState(false);
    const [previousLoadedRoom, setPreviousLoadedRoom] = useState(null);

    const nextUsers = [otherUser, otherUserB].filter(user => user.userId);
    const isThreeWayCall = nextUsers.length === 2;
    const isLoadingScreenView = !isDisplayProfile;
    const currentTimer = getSyncDateNow() - Date.parse(sessionStartTime);
    const sessionDurationMins = isPreviewMode ? 4 : Math.round((Date.parse(sessionEndTime) - Date.parse(sessionStartTime) - currentTimer) / 60 / 1000);

    useEffect(() => {
        const loadedRoom = window.sessionStorage.getItem(`room-loaded-${eventId}`);
        setPreviousLoadedRoom(loadedRoom);
        setReconnected(loadedRoom === roomNumber);

        return () => {
            setProfileView(false);
            setPreviousUsersAction([]);
            setSurveySuccessfullyPostedAction(false);
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (previousUsers.length) {
                setPublisherIsReadyAction(false);
                await reInitRoomController();
            }
        })();
    }, [previousUsers]);

    const getProfilePhoto = (user) => {
        if (!user || !user.userId) return;
        return (user.profilePhoto && user.profilePhoto !== "null") ?
            (<UserPhoto size={"100"} src={user.profilePhoto}/>) :
            (<UserInitials size={"100"} fontSize={"36"}
                           bgColor={getProfileColor(user)}>
                {getUserInitials(user)}
            </UserInitials>);
    };

    const getTooltipMessage = () => {
        const indicator = minPerSession > sessionDurationMins ? 'Shorter' : 'Longer';
        const isRematched = previousLoadedRoom && styleMatch === 'fishbowl';
        const isJoinedMidRound = !previousLoadedRoom && styleMatch === 'fishbowl';
        const isStartedEarly = Math.floor(sessionNumber) === 0;

        if (isPreviewMode) return null;
        if (isThreeWayCall && styleMatch === 'fishbowl' && !reconnected) return 'You’re joining an existing conversation.';

        if (minPerSession === sessionDurationMins) return null;
        if (isRematched) {
            if (indicator === 'Longer') return 'Longer conversation than normal.';
            return 'Shorter call because you rematched.';
        }
        if (isStartedEarly) return `${indicator} call because you started early.`;
        if (isJoinedMidRound) return `${indicator} call because you joined mid-Round.`;

        return null;
    };

    const getTextBelowCard = () => {
        if (isThreeWayCall) return '';

        const currentUserRegistration = eventRegistrations.find(({user_id})=> user_id === currentUser.userId);
        const partnerRegistration = eventRegistrations.find(({user_id})=> user_id === otherUser.userId);
        const currentUserMeets = _.get(currentUserRegistration, "meet_users", []);
        const partnerMeets = _.get(partnerRegistration, "meet_users", []);

        if (currentUserMeets && currentUserMeets.includes(otherUser.userId) && partnerMeets && partnerMeets.includes(currentUser.userId)) {
            return 'Mutual Request to Meet';
        }
        if (currentUserMeets && currentUserMeets.includes(otherUser.userId)) {
            return 'You Requested to Meet';
        }
        if (partnerMeets && partnerMeets.includes(currentUser.userId)) {
            return `${otherUser.firstName} Requested to Meet`;
        }

        return isPreviewMode ? 'Mutual Request to Meet' : '';
    };

    const getProfileButtonText = () => {
        if (isLoadingScreenView) return isThreeWayCall ? 'Show Profiles' : 'Show Profile';
        return isThreeWayCall ? 'Hide Profiles' : 'Hide Profile';
    };

    const tooltipMessage = getTooltipMessage();

    return (
        <>
            <ContainerWrapper {...{ hideNextPartnerBioPopup }} isPreviewMode={!!isPreviewMode}>
                <Container {...{ hideNextPartnerBioPopup }}>
                        <Main onClick={() => setProfileView(isLoadingScreenView)}>
                            {showTooltip && tooltipMessage &&
                                <Tooltip
                                    onClick={(e)=>e.stopPropagation()}
                                    displayArrow={!isThreeWayCall && !reconnected}
                                >
                                    {tooltipMessage}
                                    <CloseXButton
                                        style={{top: 0, right: 10}}
                                        onClick={() => setShowTooltip(false)} />
                                </Tooltip>
                            }
                            <PartnersInfo
                                sessionFormat={sessionFormat}
                                getProfilePhoto={getProfilePhoto}
                                isThreeWayCall={isThreeWayCall}
                                sessionDurationMins={sessionDurationMins}
                                numberOfSessions={numberOfSessions}
                                sessionNumber={Math.floor(sessionNumber)}
                                nextUsers={nextUsers}
                                isPreviewMode={isPreviewMode}
                            />
                            <ProfileBtnBlock>
                                {isLoadingScreenView ?
                                    <OpenProfileButton
                                        isOpen={!isLoadingScreenView}
                                        onClick={() => setProfileView(isLoadingScreenView)}
                                    >
                                        {getProfileButtonText()}
                                    </OpenProfileButton>
                                    :
                                    <HiddeProfileButton
                                        isOpen={!isLoadingScreenView}
                                    >
                                        {getProfileButtonText()}
                                    </HiddeProfileButton>
                                }
                            </ProfileBtnBlock>
                            <MutualRequestToMeet>
                                {getTextBelowCard()}
                            </MutualRequestToMeet>
                        </Main>
                </Container>
            </ContainerWrapper>
            <FakeBackground>
                <ul className="circles">
                    <li/><li/><li/><li/><li/>
                    <li/><li/><li/><li/><li/>
                </ul>
            </FakeBackground>
        </>
    );
};

const mapStateToProps = (store) => ({
    screenView: store.controller.screenView,
    currentSession: store.controller.currentSession,
    currentEvent: store.controller.currentEvent,
    surveySuccessfullyPosted: store.controller.surveySuccessfullyPosted,
    hideNextPartnerBioPopup: store.controller.hideNextPartnerBioPopup,
    connected: store.room.connected,
    partnerConnected: store.room.partnerConnected,
    endCallClicked: store.room.endCallClicked,
    publisherIsReady: store.room.publisherIsReady,
    currentUser: store.users.currentUser,
    otherUser: store.users.otherUser,
    otherUserB: store.users.otherUserB,
    previousUsers: store.users.previousUsers,
    partnersBioProgress: store.users.partnersBioProgress,
    isDisplayProfile: store.controller.isDisplayProfile,
    eventRegistrations: store.checkin.eventRegistrations,
    userSessions: store.controller.userSessions,
});

const mapDispatchToProps = {
    setPreviousUsersAction,
    setPublisherIsReadyAction,
    setSurveySuccessfullyPostedAction,
    joinIsClickedAction,
    reInitRoomController,
    setProfileView
};

export default connect(mapStateToProps, mapDispatchToProps)(NextPartnerBio);
