import { useEffect } from 'react';
import store from "../../../../../store";
import socketModule from "../../../../../utils/socketModule";

export default function useHandleRoomDisconnection(
  room,
  onError,
  removeLocalAudioTrack,
  removeLocalVideoTrack,
  isSharingScreen,
  toggleScreenShare
) {
  useEffect(() => {
    if (room) {
      const onDisconnected = (_, error) => {
        console.info('[Hooks] Room: ' + room.name + ' has disconnected', {room});

        //roomController this.session.on('streamDestroyed', (event) => {...
        const {controller: {currentEvent: {eventId}, currentSession: {roomNumber, numberUsers}}} = store.getState();
        socketModule.socket.emit('fishbowl.session_stream_destroyed', {eventId, roomNumber, numberUsers});

        if (error) {
          onError(error);
          if (error.code === 20104) {
            console.error('Signaling reconnection failed due to expired AccessToken!');
          } else if (error.code === 53000) {
            console.error('Signaling reconnection attempts exhausted!');
          } else if (error.code === 53002) {
            console.error('Signaling reconnection took too long!');
          }
        }

        removeLocalAudioTrack();
        removeLocalVideoTrack();
        if (isSharingScreen) {
          toggleScreenShare();
        }
      };

      room.on('disconnected', onDisconnected);
      return () => {
        room.off('disconnected', onDisconnected);
      };
    }
  }, [room, onError, removeLocalAudioTrack, removeLocalVideoTrack, isSharingScreen, toggleScreenShare]);
}
