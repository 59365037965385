import React from "react";
import styled from "styled-components";
import Timer from "./Timer";
import { connect } from "react-redux";
import sound from "src/assets/careernumbers_next_box__3_.mp3";
import socketModule from "../../utils/socketModule";
import {
    clearAcceptedInviteAction,
    logging,
    setPartyViewAction,
    setUserSessionPartyViewAction,
    show3rdPartyPopupAction
} from "../../store/controller/actions";
import { AcceptButton, DeclineButton, Popup, PopupContainer, PopupTimerWrapper, PopupWrapper } from "./styled";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDataOptions, getSyncDateNow, getUserCompanyInfo } from "../../utils/helpers";
import { setInvitedUserAction } from "../../store/users/actions";

const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const UserInitials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid lightgray;
  background-color: ${pr => pr.bgColor ? pr.bgColor : '#777'};
  color: #fff;
  font-weight: bold;
  font-size: 18px;
`;

const UserPhoto = styled.div`
  margin-right: 7px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-image: url(${pr => pr.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const UserName = styled.div`
  line-height: 1.2;
  padding-top: 2px;
  font-size: 13px;
`;
const Info = styled.div`
  color: #989898;
`;

const initVariants = (otherUser, invitedUserName) => {
    return {
        oDeclined: {
            text: `${otherUser.firstName} passed`,
        },
        iDeclined: {
            text: 'Okay, thanks!',
        },
        inviting: {
            text: `${otherUser.firstName} approved. ${invitedUserName} joining`,
        },
        accept: {
            text: `${invitedUserName} joining`,
        },
        ask: {
            text: 'Somebody is between conversations',
        },
        oPartnerFound: {
            text: 'We\'ve located another set of attendees.',
        }
    }
};

class Add3rdUserToCall extends React.Component {

    state = {
        variants: null,
        iAccept: false,
    };

    _audio = new Audio(sound);
    _interval = null;
    _timeout = null;

    componentWillUnmount() {
        socketModule.socket.off('fishbowl.destroyed');

        if (this.props.checkDesignMode) {
            return;
        }
        this.props.setPartyViewAction(null);
        this.props.setInvitedUserAction(null);
        this.props.clearAcceptedInviteAction();
        (async () => {
            await this.decline(true)
        })();
        if (this._timeout) {
            clearTimeout(this._timeout)
        }
        if (this._interval) {
            clearInterval(this._interval)
        }
    }

    componentDidMount() {
        socketModule.socket.on('fishbowl.destroyed', ({eventId, userId}) => {
            if (this.props.currentEvent.eventId === eventId && this.props.invitedUser.userId === userId) {
                this.props.setPartyViewAction('oPartnerFound');
                clearInterval(this._interval);
            }
        });

        this.setState({variants: initVariants(this.props.otherUser, this.getInvitedUserName())}, () => {
            this._audio.play();
        });


        if (this.props.checkDesignMode) {
            return;
        }
        this._timeout = setTimeout(() => {
            this.props.show3rdPartyPopupAction(false);
        }, 30 * 1000);

        const maxTime = Date.parse(this.props.currentSession.sessionEndTime) - 90 * 1000;
        this._interval = setInterval(() => {
            if (getSyncDateNow() > maxTime) {
                this.props.setPartyViewAction('oPartnerFound');
                clearInterval(this._interval);
            }
        }, 1000)
    }

    getInvitedUserName = () => {
        return `${this.props.invitedUser.firstName}`;
    };

    getUserInitials = () => {
        return (this.props.invitedUser.firstName.slice(0, 1) + this.props.invitedUser.lastName.slice(0, 1)).toUpperCase();
    };

    getProfileColor = () => {
        return this.props.invitedUser.profileColorHex;
    };

    accept = async () => {
        if (this.props.checkDesignMode || this.state.iAccept) {
            return;
        }

        this.setState({iAccept: true});
        await this.props.setUserSessionPartyViewAction('accept');
        this.props.logging({message: `Opted <b>sure</b> option in 3rd party popup | <small> Add3rdUserToCall(136) </small>`});

        socketModule.socket.emit('3rd-party.accept_invite', {
            eventId: this.props.currentEvent.eventId,
            roomNumber: this.props.currentSession.roomNumber
        });
    };

    decline = async (timeout = false) => {
        if (this.props.checkDesignMode) {
            return;
        }
        if (this.props.variant === 'oPartnerFound') {
            return;
        }
        if (!timeout) {
            await this.props.setUserSessionPartyViewAction('decline');
            this.props.logging({message: `Opted <b>no thanks</b> option in 3rd party popup | <small> Add3rdUserToCall(145) </small>`});
        }
        socketModule.socket.emit('3rd-party.decline_invite', {
            eventId: this.props.currentEvent.eventId,
            roomNumber: this.props.currentSession.roomNumber,
            invitedUserId: this.props.invitedUser.userId,
            sessionNumber: this.props.currentSession.sessionNumber
        });
    };

    getPopupText = (variant) => {
        const {variants} = this.state;
        const {otherUser} = this.props;

        if (!variants) return null;

        switch (variant) {
            case 'iDeclined':
            case 'oPartnerFound':
            case 'ask':
                return variants[variant].text;
            case 'accept':
                return <><var>{this.getInvitedUserName()}&nbsp;</var>joining</>
            case 'oDeclined':
                return <><var>{otherUser.firstName}&nbsp;</var>passed</>
            case 'inviting':
                return <>
                    <var>{otherUser.firstName}&nbsp;</var>approved
                    <var>&nbsp;{this.getInvitedUserName()}&nbsp;</var>joining
                </>
            case 'found':
                return <>Attendee found other conversation</>
            default:
                return null
        }
    }

    render() {
        return (
            <PopupContainer>
                <PopupWrapper>
                    {this.props.variant === 'ask' ?
                        <Popup>
                            <UserInfoWrapper>
                                {this.props.invitedUser.profilePhoto !== '' ?
                                    (<UserPhoto src={this.props.invitedUser.profilePhoto}/>) :
                                    (<UserInitials bgColor={this.getProfileColor()}>
                                        {this.getUserInitials()}
                                    </UserInitials>)
                                }
                                <UserName>
                                    Add {this.getInvitedUserName()}?
                                    <Info>
                                        {getUserCompanyInfo(this.props.invitedUser)}
                                    </Info>
                                </UserName>
                            </UserInfoWrapper>
                            <PopupTimerWrapper>
                                <Timer seconds="30" onTimeEnd={async () => {
                                    await this.decline(true)
                                }}/>s
                            </PopupTimerWrapper>
                            <AcceptButton
                                marginLeft={'20px'}
                                width={'115px'}
                                onClick={this.accept}
                                data-trackable={
                                    getDataOptions({
                                        view: 'Add3rdUserToCall',
                                        type: 'button',
                                        label: 'Add to call'
                                    })
                                }
                            >
                                <FontAwesomeIcon icon={faUserPlus} style={{marginRight: '10px'}}/>
                                Add to call
                            </AcceptButton>
                            <DeclineButton
                                data-trackable={
                                    getDataOptions({
                                        view: 'Add3rdUserToCall',
                                        type: 'button',
                                        label: 'Pass'
                                    })
                                }
                                onClick={async () => await this.decline()}>Pass</DeclineButton>
                        </Popup> :
                        <Popup>
                            {this.getPopupText(this.props.variant === 'inviting' && this.state.iAccept ? 'accept' : this.props.variant)}
                        </Popup>
                    }
                </PopupWrapper>
            </PopupContainer>
        );
    }
}

export default connect(
    (state) => ({
        checkDesignMode: state.controller.checkDesignMode,
        otherUser: state.users.otherUser,
        invitedUser: state.users.invitedUser,
        currentSession: state.controller.currentSession,
        currentEvent: state.controller.currentEvent,
        partyView: state.controller.partyView
    }),
    {
        show3rdPartyPopupAction,
        setPartyViewAction,
        clearAcceptedInviteAction,
        setInvitedUserAction,
        setUserSessionPartyViewAction,
        logging
    }
)(Add3rdUserToCall)
