import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import moment from "moment-timezone";
import {
    PopupContainer,
    PopupWrapper,
    Popup,
    AcceptButton,
    PopupTimerWrapper,
    PopupWrapperNoAnimation
} from "./styled";
import socketModule from '../../utils/socketModule';
import Timer from './Timer';
import {
    hideJoinBy3rdPartyAction,
    processMovingToRoom,
    setVideoCompletedAction,
    switchView,
    setExtendCallBlockedAction,
    setSelectedNextSessionVariantAction,
    logging,
    showRightFlyInPopupAction, moveToWrapupAction,
    getCurrentUserSession,
} from "../../store/controller/actions";
import {setEndCallClickedAction} from '../../store/room/actions';
import sound from '../../assets/careernumbers_next_box__3_.mp3';
import {getDataOptions, isCheckInFlow, getSyncDateNow} from '../../utils/helpers';
import {setNeedShowGreenScreenAction} from '../../store/checkin/actions';
import {getFlowByAlias, getStepByAlias} from '../../store/preview/actions';

const PopupTitle = styled.div`
  font-size: 16px;
`;

class NewMeet extends React.Component {
    _audio = new Audio(sound);
    _timeout = null;

    state =  {
        isLastSession: false
    };

    componentWillUnmount() {
        if (this._timeout) {
            clearTimeout(this._timeout)
        }
        const fadeScreen = document.getElementById('fade-screen');
        if (fadeScreen) fadeScreen.className = '';
    }

    async componentDidMount() {
        this.setState({ isLastSession: this.isLastSession() })
        if (this.isLastSession()) {
            this.props.setSelectedNextSessionVariantAction('exit');
            this._audio.play();
        } else {
            this._audio.play();
        }
        this._timeout = setTimeout(() => {
            const fadeScreen = document.getElementById('fade-screen');
            if (fadeScreen) fadeScreen.className = 'active half-opacity';
        }, 30 * 1000);
    }

    setVideoCompleted = async () => {
        const {isNextMeetReadyModal = false, history, currentEvent, getFlowByAlias, getStepByAlias} = this.props;
        if (isNextMeetReadyModal) {
            return history.push(`/organizer/preview/${currentEvent.eventId}/${getFlowByAlias('event').id}/${getStepByAlias('bioModalOnEvent').id}`);
        }
        if (this.props.checkDesignMode) {
            return;
        }
        if (this.state.isLastSession && isCheckInFlow()) {
            return this.props.moveToWrapupAction();
        }
        if (isCheckInFlow()) {
            this.props.showRightFlyInPopupAction(false);
            this.props.setNeedShowGreenScreenAction(false);
            return this.props.history.push(`/live-event/${this.props.currentEvent.eventId}`);
        }

        this.props.setExtendCallBlockedAction(false);
        this.props.setEndCallClickedAction(false);

        const {currentSession: {roomNumber: currentRoomNumber, numberUsers}, otherUser, otherUserB} = this.props;
        if (parseInt(numberUsers) === 3 && otherUser.userId && otherUserB.userId) {
            socketModule.socket.emit('3way_call.user_moved_to_next_room', {
                eventId: this.props.currentEvent.eventId,
                userId: this.props.currentUser.userId,
                roomNumber: currentRoomNumber
            })
        }

        if (this.props.moveToRoom === true) {
            await this.props.processMovingToRoom();
            const currentUserSession = await this.props.getCurrentUserSession();
            const newRoomNumber = currentUserSession ? currentUserSession.room_number : '';
            this.props.logging({message: `Switched to <b>video</b> screen. Room number: ${newRoomNumber} | <small> NewMeet(49) </small>`});
            await this.props.switchView('video');
            const path = `/live-event/${this.props.currentEvent.eventId}/${newRoomNumber}`;
            socketModule.socket.emit('fishbowl.invited_user_moving_to_room', {
                eventId: this.props.currentEvent.eventId,
                roomNumber: newRoomNumber
            });
            socketModule.socket.emit('bio_progress.complete_prior_call', {
                eventId: this.props.currentEvent.eventId,
                userId: this.props.currentUser.userId,
                roomNumber: newRoomNumber
            });
            window.location.href = path;
        } else {
            const nextView = await this.props.setVideoCompletedAction({});
            if (nextView === null && this.props.currentSession.bothConnected) return;

            this.props.logging({
                    message: nextView === 'video' ?
                        `Switched to <b>${nextView}</b> screen. Room number: ${this.props.currentSession.roomNumber} | <small> NewMeet(61) </small>` :
                        `Switched to <b>${nextView}</b> screen. | <small> NewMeet(63) </small>`
                }
            );

            if (nextView === 'video' || !this.props.currentSession.bothConnected) {
                this.props.switchView('video');
                const currentUserSession = await this.props.getCurrentUserSession();
                const roomNumber = currentUserSession ? currentUserSession.room_number : '';
                window.location.href = `/live-event/${this.props.currentEvent.eventId}/${roomNumber}`;
            } else {
                this.props.switchView(nextView);
            }
        }
        this.props.hideJoinBy3rdPartyAction(false);
    };

    isLastSession = () => {
        const lastSession = this.props.eventSessions.find(session => parseInt(session.number) === parseInt(this.props.currentEvent.numberOfSessions));
        if (!lastSession) return false;
        return moment(lastSession.end).diff(getSyncDateNow(), 'minutes') < 1;
    };

    getPopup = () => (
        <Popup>
            <PopupTitle>
                {this.isLastSession() && !this.props.isPreviewMode ? 'Wrapup Event' : 'Next Meet Ready'}
            </PopupTitle>
            <PopupTimerWrapper>
                <Timer seconds="45" onTimeEnd={this.setVideoCompleted}/>s
            </PopupTimerWrapper>
            <AcceptButton
                marginLeft={'20px'}
                data-trackable={
                    getDataOptions({
                        view: 'NewMeet',
                        type: 'button',
                        label: 'Go'
                    })
                }
                onClick={this.setVideoCompleted}>Go</AcceptButton>
        </Popup>)

    render() {
        const {isNextMeetReadyModal} = this.props;
        return (
            <PopupContainer style={{top: 0}} isNextMeetReadyModal={isNextMeetReadyModal}>
                {!isNextMeetReadyModal
                    ? <PopupWrapper>
                        {this.getPopup()}
                    </PopupWrapper>
                    : <PopupWrapperNoAnimation>
                        {this.getPopup()}
                    </PopupWrapperNoAnimation>
                }
            </PopupContainer>
        );
    }
}

export default withRouter(
    connect(
        store => ({
            eventSessions: store.checkin.eventSessions,
            checkDesignMode: store.controller.checkDesignMode,
            screenView: store.controller.screenView,
            currentEvent: store.controller.currentEvent,
            currentSession: store.controller.currentSession,
            nextSessionVariant: store.controller.nextSessionVariant,
            extendedCall: store.controller.extendedCall,
            nextPartner: store.users.nextPartner,
            currentUser: store.users.currentUser,
            otherUser: store.users.otherUser,
            otherUserB: store.users.otherUserB,
            isPreviewMode: store.preview.isPreviewMode
        }),
        {
            setVideoCompletedAction,
            switchView,
            hideJoinBy3rdPartyAction,
            processMovingToRoom,
            setExtendCallBlockedAction,
            setSelectedNextSessionVariantAction,
            setEndCallClickedAction,
            showRightFlyInPopupAction,
            setNeedShowGreenScreenAction,
            logging,
            getStepByAlias,
            getFlowByAlias,
            moveToWrapupAction,
            getCurrentUserSession,
        })(NewMeet)
);
