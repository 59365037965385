import React, { createContext, useCallback, useEffect, useState } from "react";
import { SelectedParticipantProvider } from "./useSelectedParticipant/useSelectedParticipant";

import AttachVisibilityHandler from "./AttachVisibilityHandler/AttachVisibilityHandler";
import useBackgroundSettings from "./useBackgroundSettings/useBackgroundSettings";
import useHandleRoomDisconnection from "./useHandleRoomDisconnection/useHandleRoomDisconnection";
import useHandleTrackPublicationFailed from "./useHandleTrackPublicationFailed/useHandleTrackPublicationFailed";
import useLocalTracks from "./useLocalTracks/useLocalTracks";
import useRestartAudioTrackOnDeviceChange
    from "./useRestartAudioTrackOnDeviceChange/useRestartAudioTrackOnDeviceChange";
import useRoom from "./useRoom/useRoom";
import useScreenShareToggle from "./useScreenShareToggle/useScreenShareToggle";

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */


export const VideoContext = createContext(null);

export function VideoProvider({options, children, isWebinar, onError = () => {}}) {
    const onErrorCallback = useCallback(
        error => {
            console.log(`ERROR: ${error.message}`, error);
            onError(error);
            const hasRefreshed = JSON.parse(
                window.sessionStorage.getItem('twilio-refreshed') || 'false'
            );
            if (!hasRefreshed) { // not been refreshed yet
                window.sessionStorage.setItem('twilio-refreshed', 'true'); // we are now going to refresh
                return window.location.reload(); // refresh the page
            }
        },
        [onError]
    );

    const {
        localTracks,
        getLocalVideoTrack,
        getLocalAudioTrack,
        isAcquiringLocalTracks,
        removeLocalAudioTrack,
        removeLocalVideoTrack,
        getAudioAndVideoTracks
    } = useLocalTracks();
    const {room, isConnecting, isConnected, connect} = useRoom(localTracks, onErrorCallback, options);

    const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(room, onError, isWebinar);

    // Register callback functions to be called on room disconnect.
    useHandleRoomDisconnection(
        room,
        onError,
        removeLocalAudioTrack,
        removeLocalVideoTrack,
        isSharingScreen,
        toggleScreenShare
    );
    useHandleTrackPublicationFailed(room, onError);
    useRestartAudioTrackOnDeviceChange(localTracks);

    const [isBackgroundSelectionOpen, setIsBackgroundSelectionOpen] = useState(false);
    const videoTrack = localTracks.find(track => !track.name.includes("screen") && !track.name.includes("video-composer-presentation") && track.kind === "video");
    const [backgroundSettings, setBackgroundSettings, backgroundConfig] = useBackgroundSettings({
        videoTrack,
        room,
    });
    const [horizontablePosition, setHorizontablePosition] = useState(false);
    const [videoTracksParams, setVideoTracksParams] = useState({});

    useEffect(() => {
        const handleResize = () => {
            const isHorizontable = window.matchMedia("(orientation: landscape)").matches;
            setHorizontablePosition(isHorizontable);
        }
        window.addEventListener('resize', handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <VideoContext.Provider
            value={{
                room,
                localTracks,
                isConnecting,
                isConnected,
                horizontablePosition,
                onError: onErrorCallback,
                getLocalVideoTrack,
                getLocalAudioTrack,
                connect,
                isAcquiringLocalTracks,
                removeLocalVideoTrack,
                removeLocalAudioTrack,
                isSharingScreen,
                toggleScreenShare,
                getAudioAndVideoTracks,
                isBackgroundSelectionOpen,
                setIsBackgroundSelectionOpen,
                backgroundSettings,
                setBackgroundSettings,
                videoTracksParams,
                setVideoTracksParams,
                backgroundConfig
            }}
        >
            <SelectedParticipantProvider room={room}>{children}</SelectedParticipantProvider>
            {/*
        The AttachVisibilityHandler component is using the useLocalVideoToggle hook
        which must be used within the VideoContext Provider.
      */}
            <AttachVisibilityHandler/>
        </VideoContext.Provider>
    );
}
