import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { LoginCallback } from "@okta/okta-react";
import styled from "styled-components";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "normalize.css/normalize.css";
import "src/assets/css/fonts.css";
import "src/assets/css/screens.css";

import { getSyncDateNow, isCheckInFlow, lazyRetry } from "./utils/helpers";
import config from "./utils/config";
import appConfig from "./utils/config";
import { getSystemToken, logout } from "./utils/AuthManager";
import socketModule from "./utils/socketModule";
import { setLoggedInSystemAction } from "./store/registration/actions";
import { getCurrentEventSessionAction, setTargetJoinPopupAction } from "./store/checkin/actions";
import {
    logging,
    showFishbowlPopupAction,
    showNextSessionPopupAction,
    showRightFlyInPopupAction
} from "./store/controller/actions";
import { userSeenNextSessionPopupAction } from "./store/room/actions";
import { setAccountAction } from "./store/home/actions";
import { saveUserAction, setPartnersBioProgressesAction } from "./store/users/actions";
import Loader from "./components/Loader";
import LoadingContainer from "./components/Containers/LoadingContainer";
import VideoContainer from "./components/Containers/VideoContainer";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faBan,
    faBookmark,
    faBullhorn,
    faBullseye,
    faCalendarCheck,
    faCalendarMinus,
    faCaretDown,
    faCaretRight,
    faChartBar,
    faChartLine,
    faCheck,
    faClock,
    faCog,
    faComments,
    faCopy,
    faCrown,
    faEdit,
    faEnvelope,
    faEye,
    faEyeSlash,
    faFlagCheckered,
    faGem,
    faHandshake,
    faIdCard,
    faLink,
    faLock,
    faLongArrowAltDown,
    faPaintBrush,
    faPencilAlt,
    faPlay,
    faPlayCircle,
    faPlug,
    faShare,
    faSignOutAlt,
    faStar,
    faStopCircle,
    faTags,
    faThumbtack,
    faTicketAlt,
    faTimes,
    faTimesCircle,
    faTrashAlt,
    faUser,
    faUserCircle,
    faUsers,
    faUserTimes,
    faVideo
} from "@fortawesome/free-solid-svg-icons";
import { getSystemSettingsAction } from "./store/system/action";

library.add(
    faBan, faChartBar, faGem, faIdCard, faHandshake, faCopy, faLink, faClock,
    faPencilAlt, faBullseye, faUserCircle, faEnvelope, faPlayCircle, faComments,
    faBullhorn, faCrown, faStar, faFlagCheckered, faTimes, faUser, faUsers,
    faLock, faTags, faCalendarCheck, faPaintBrush, faCheck, faTrashAlt,
    faVideo, faStopCircle, faPlay, faEdit, faTicketAlt, faUserTimes,
    faTimesCircle, faChartLine, faPlug, faEye, faEyeSlash, faThumbtack,
    faLongArrowAltDown, faCaretDown, faShare, faBookmark, faCalendarMinus,
    faSignOutAlt, faCaretRight, faCog,
);

const Sidebar = lazy(() => lazyRetry(() => import(/* webpackPrefetch: true, webpackChunkName: "Sidebar" */ "./components/ContainerElements/Sidebar"), 'Sidebar'));
const FishbowlHandler = lazy(() => lazyRetry(() => import(/* webpackPrefetch: true, webpackChunkName: "FishbowlHandler" */ "./components/ContainerElements/FishbowlHandler"), 'FishbowlHandler'));
const NewMeetPopup = lazy(() => lazyRetry(() => import(/* webpackPrefetch: true, webpackChunkName: "NewMeetPopup" */ "./components/Popups/NewMeet"), 'NewMeetPopup'));
const FishbowlPopup = lazy(() => lazyRetry(() => import(/* webpackPrefetch: true, webpackChunkName: "FishbowlPopup" */ "./components/Popups/Fishbowl"), 'FishbowlPopup'));
const CheckInFlow = lazy(() => lazyRetry(() => import(/* webpackChunkName: "CheckInFlow" */ "./components/CheckInFlow"), 'CheckInFlow'));
const DirectWebcamPage = lazy(() => lazyRetry(() => import(/* webpackChunkName: "DirectWebcamPage" */ "./components/CheckInFlow/pages/DirectWebcamPage"), 'DirectWebcamPage'));
const DirectoryFlow = lazy(() => lazyRetry(() => import(/* webpackChunkName: "DirectoryFlow" */ "./components/DirectoryFlow"), 'DirectoryFlow'));
const WrapupContainer = lazy(() => lazyRetry(() => import(/* webpackChunkName: "WrapupContainer" */ "./components/WrapupFlow/WrapupContainer"), 'WrapupContainer'));
const OktaWrapper = lazy(() => lazyRetry(() => import(/* webpackChunkName: "OktaWrapper" */ "./components/Auth/OktaWrapper"), 'OktaWrapper'));
const MainGuard = lazy(() => lazyRetry(() => import(/* webpackChunkName: "MainGuard" */ "./components/Auth/MainGuard"), 'MainGuard'));
const ResetPasswordPage = lazy(() => lazyRetry(() => import(/* webpackChunkName: "ResetPasswordPage" */ "./components/Auth/ResetPasswordPage"), 'ResetPasswordPage'));
const SurveyPopup = lazy(() => lazyRetry(() => import(/* webpackChunkName: "SurveyPopup" */ "./components/Popups/Survey")), 'SurveyPopup');
const EmailPreference = lazy(() => lazyRetry(() => import(/* webpackChunkName: "EmailPreference" */ "./components/Common/EmailPreference/EmailPreference"), 'EmailPreference'));
const EmailSettingsPage = lazy(() => lazyRetry(() => import(/* webpackChunkName: "EmailSettingsPage" */ "./components/Common/EmailSettings/EmailSettingsPage"), 'EmailSettingsPage'));
const ForbiddenPage = lazy(() => lazyRetry(() => import(/* webpackChunkName: "ForbiddenPage" */ "./components/Common/403"), 'ForbiddenPage'));
const NotFoundPage = lazy(() => lazyRetry(() => import(/* webpackChunkName: "NotFoundPage" */ "./components/Common/404"), 'NotFoundPage'));
const CreateSubscription = lazy(() => lazyRetry(() => import(/* webpackChunkName: "CreateSubscription" */ "./components/CreateSubscription"), 'CreateSubscription'));
const Settings = lazy(() => lazyRetry(() => import(/* webpackChunkName: "Settings" */ "./components/Settings/Settings"), 'Settings'));
const HomePage = lazy(() => lazyRetry(() => import(/* webpackChunkName: "HomePage" */ "./components/HomePage/HomePage"), 'HomePage'));
const PreviewFlow = lazy(() => lazyRetry(() => import(/* webpackChunkName: "PreviewFlow" */ "./components/PreviewFlow"), 'PreviewFlow'));
const OrganizerFlow = lazy(() => lazyRetry(() => import(/* webpackChunkName: "OrganizerFlow" */ './components/OrganizerFlow'), 'OrganizerFlow'));
const AdminFlow = lazy(() => lazyRetry(() => import(/* webpackChunkName: "AdminFlow" */ './components/AdminFlow'), 'AdminFlow'));
const CreateEvent = lazy(() => lazyRetry(() => import(/* webpackChunkName: "CreateEvent" */ './components/CreateEvent'), 'CreateEvent'));
const LoginPage = lazy(() => lazyRetry(() => import(/* webpackChunkName: "LoginPage" */ './components/Auth/Login'), 'LoginPage'));
const EventRegistrationContainer = lazy(() => lazyRetry(() => import(/* webpackChunkName: "EventRegistrationContainer" */ './components/RegistrationFlow/EventRegistrationContainer'), 'EventRegistrationContainer'));
const BreakContainer = lazy(() => lazyRetry(() => import(/* webpackPrefetch: true, webpackChunkName: "BreakContainer" */ './components/Containers/BreakContainer'), 'BreakContainer'));
const HoldingContainer = lazy(() => lazyRetry(() => import(/* webpackPrefetch: true, webpackChunkName: "HoldingContainer" */ './components/Containers/HoldingContainer'), 'HoldingContainer'));
const LiveAttendeeStatsOrganizerContainers = lazy(() => lazyRetry(() => import(/* webpackChunkName: "LiveAttendeeStatsOrganizerContainers" */ './components/Containers/LiveAttendeeStatsOrganizerContainers'), 'LiveAttendeeStatsOrganizerContainers'));
const TicketCanceled = lazy(() => lazyRetry(() => import(/* webpackChunkName: "TicketCanceled" */ "./components/Common/TicketCanceled/TicketCanceled"), "TicketCanceled"));
const Webinar = lazy(() => lazyRetry(() => import(/* webpackChunkName: "TicketCanceled" */ "./components/TwilioVideo/Webinar"), "Webinar"));
const EventBreaks = lazy(() => lazyRetry(() => import(/* webpackChunkName: "EventBreaks" */ "./components/Popups/EventBreaks"), "EventBreaks"));
//const SeparatePageForRecordVideo = lazy(() => lazyRetry(() => import(/* webpackChunkName: "EventBreaks" */ "./components/Common/SeparatePageForRecordVideo"), "SeparatePageForRecordVideo"));
const SeparatePageForNewRecordVideo = lazy(() => lazyRetry(() => import(/* webpackChunkName: "EventBreaks" */ "./components/Common/SeparatePageForNewRecordVideo"), "SeparatePageForNewRecordVideo"));
const Faq = lazy(() => lazyRetry(() => import(/* webpackChunkName: "EventBreaks" */ "./components/Common/Faq"), "Faq"));
const MuxRoom = lazy(() => lazyRetry(() => import(/* webpackChunkName: "EventBreaks" */ "./components/TwilioVideo/Webinar/components/Mux/MuxRoom"), "MuxRoom"));

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden !important;

  .ReactCollapse--collapse {
    transition: height 300ms linear;
  }
`;

class App extends React.Component {
    state = {
        showFishbowlPopup: false,
        fishbowlView: null,
        fishbowlButtonSelected: null,
        fishbowlPartyView: null,
        fishbowlUpdated: false,
    };

    componentDidMount() {
        this.props.getSystemSettingsAction();

        const systemToken = getSystemToken("jwt_token");
        if (!systemToken) {
            localStorage.removeItem("jwt_token");
        } else {
            this.props.setLoggedInSystemAction(true);
        }

        socketModule.socket.on("show-popup", async ({type, sessionUsersIds}) => {
            if (
                this.props.showFishbowlPopup &&
                this.props.fishbowlPartyView !== "found"
            ) {
                if (!this.props.sessionHasBeenExtended && !this.sessionEndInFuture()) {
                    this.props.showFishbowlPopupAction(false);
                }
            }
            if (
                !this.props.showFishbowlPopup ||
                !(
                    this.props.showFishbowlPopup &&
                    this.props.fishbowlPartyView === "found"
                )
            ) {
                if (
                    !this.sessionEndInFuture() && sessionUsersIds &&
                    sessionUsersIds.includes(this.props.currentUser.userId)
                ) {
                    switch (type) {
                        case "right-fly-in":
                            this.props.setTargetJoinPopupAction(null);

                            if (
                                !this.props.availableToMatch ||
                                (this.props.stepsCount > 1 &&
                                    this.props.eventRegistration.currentStep < 2)
                            )
                                return;

                            this.props.showNextSessionPopupAction(false);
                            await this.props.getCurrentEventSessionAction();
                            this.props.showRightFlyInPopupAction(true);
                            if (this.showRightFlyIn()) {
                                this.props.logging({
                                    message: `See Right FlyIn popup. | <small> App(78) </small>`,
                                });
                            }
                            break;
                        default:
                            return;
                    }
                }
            }
        });

        socketModule.socket.on("seen_next_session_popup", async ({userId}) => {
            this.props.userSeenNextSessionPopupAction(userId);
        });

        socketModule.socket.on("system.check_client_connection", () => {
            const isDirectory = document.location.pathname.includes('directory');
            socketModule.socket.emit("system.check_client_connection", {
                eventId: this.props.currentEvent.eventId,
                userId: this.props.currentUser.userId,
                isDirectory
            });
        });
        socketModule.socket.on("system.disconnect_session", () => {
            if (
                getSyncDateNow() < Date.parse(this.props.currentSession.sessionEndTime)
            ) {
                return console.warn("session in progress skip disconnect");
            }
            console.warn("Disconnect session by scheduled job");
           // roomController.disconnectSession();
            if (this.props.twilioRoomInstance) this.props.twilioRoomInstance.disconnect();
        });
        socketModule.socket.on(
            "bio_progress.partners_progresses",
            async ({partnersProgresses}) => {
                if (!this.props.partnersBioProgressesFetched) {
                    this.props.setPartnersBioProgressesAction(partnersProgresses);
                }
            }
        );

        // Logging of user actions:
        document.addEventListener("click", async ({target}) => {
            if (target && target.dataset && target.dataset.trackable) {
                const {view, type, label, destination} = JSON.parse(
                    target.dataset.trackable
                );

                this.props.saveUserAction({view, type, label, destination, target});
            }
        });
    }

    componentWillUnmount() {
        document.removeEventListener("click", () => {
        });
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (
            this.props.currentEvent.eventId !== props.currentEvent.eventId &&
            props.currentEvent.eventId &&
            props.currentEvent.enableTranslation
        ) {
            // eslint-disable-next-line no-undef
            Localize.initialize({
                key: appConfig.LOCALIZE_API_KEY,
                rememberLanguage: true,
                autoApprove: true,
                autodetectLanguage: true,
                // other options go here, separated by commas
            });
            console.warn("Localize.initialize");
            // .no-translate to https://app.localizejs.com/settings/project/exclude-phrases
        }

        if (
            this.state.showFishbowlPopup !== props.showFishbowlPopup ||
            this.state.fishbowlView !== props.fishbowlView ||
            this.state.fishbowlButtonSelected !== props.fishbowlButtonSelected ||
            this.state.fishbowlPartyView !== props.fishbowlPartyView
        ) {
            this.setState({
                showFishbowlPopup: props.showFishbowlPopup,
                fishbowlView: props.fishbowlView,
                fishbowlButtonSelected: props.fishbowlButtonSelected,
                fishbowlPartyView: props.fishbowlPartyView,
                fishbowlUpdated: true,
            });
        } else {
            this.setState({
                fishbowlUpdated: false,
            });
        }
        if (props.currentUser.userId && this.props.currentUser.userId !== props.currentUser.userId) {
            // // AC integration
            // initAC();
            // eslint-disable-next-line no-undef
            //vgo('setAccount', appConfig.AC_ACCOUNT);
            // eslint-disable-next-line no-undef
            //vgo('setTrackByDefault', true);
            // eslint-disable-next-line no-undef
            //vgo('setEmail', props.currentUser.email);
            // eslint-disable-next-line no-undef
            //vgo('process');

            socketModule.socket.emit('logout_all_browsers', {userId: props.currentUser.userId});
            socketModule.socket.on(
                `logout_all_browsers`,
                () => {
                    if (!this.props.initLogoutOtherBrowser) {
                        logout()
                    }
                }
            );

            socketModule.socket.emit('add_account', {userId: props.currentUser.userId});
            socketModule.socket.on(
                `add_account`,
                ({account}) => {
                    this.props.setAccountAction({account});
                }
            );
        }
    }

    sessionEndInFuture = () => {
        const now = new Date(getSyncDateNow());
        const future = now.setSeconds(now.getSeconds() + 50);
        return Date.parse(this.props.currentSession.sessionEndTime) > future;
    };

    showRightFlyIn = () => {
        if (this.props.showNextPartnerBioPopup) {
            return false;
        }

        return (
            this.props.showRightFlyInPopup &&
            (this.props.nextSessionVariant !== "extend" ||
                (this.props.nextSessionVariant === "extend" &&
                    this.props.needShowRightFlyin)) &&
            (this.props.screenView === "video" ||
                this.props.screenView === "break" ||
                isCheckInFlow('check-in/welcome'))
        );
    };

    showFishbowlPopup = () => {
        const {
            accessGrantedErrorBlock,
            partnerConnected,
            joinIsClicked,
            endCallClicked,
            currentSession: {bothConnected},
            showFishbowlPopup,
            fishbowlView,
            screenView,
            showNextPartnerBioPopup,
            showFoundRightFlyIn
        } = this.props;

        const showPopup = showFishbowlPopup === true;
        const issetFisbowlView = fishbowlView !== "";
        const rightScreenView = screenView === "video" || screenView === "break";

        if (showNextPartnerBioPopup) {
            //console.warn('showNextPartnerBioPopup', showNextPartnerBioPopup);
            return false;
        }

        if (showFoundRightFlyIn && this.props.fishbowlPartyView !== 'found') {
            console.warn('showFoundRightFlyIn', showFoundRightFlyIn);
            return false;
        }

        if (accessGrantedErrorBlock) {
            //console.warn('accessGrantedErrorBlock', accessGrantedErrorBlock);
            return false;
        }

        if (partnerConnected) {
            //console.warn('partnerConnected', partnerConnected);
            return false;
        }

        if (!joinIsClicked && !bothConnected && screenView === "video") {
            //console.warn('!joinIsClicked && !bothConnected && screenView === "video"', {joinIsClicked, bothConnected, screenView, endCallClicked});

            if (!endCallClicked) return false;
        }

        const show = showPopup && issetFisbowlView && rightScreenView;

        console.warn('show', show, {showPopup, issetFisbowlView, rightScreenView});

        if (show && this.state.fishbowlUpdated) {
            this.props.logging({
                message: `See fishbowl popup (
                            fishbowlView: <b>${this.props.fishbowlView}</b>,
                            button: <b>${this.props.fishbowlButtonSelected}</b>,
                            partyView: <b>${this.props.fishbowlPartyView}</b>)
                             | <small> App(204) </small>`,
            });
        }

        return show;
    };

    showSurveyPopup = () => {
        const {showSurveyPopup, screenView, needShowSurveyPopup} = this.props;
        return showSurveyPopup && (screenView === "survey" || needShowSurveyPopup);
    };

    render() {
        const {screenView, isDisplayProfile} = this.props;
        return (
            <Container id={"main-container"}>
                {config.APP_ENV === "develop" && <Helmet>
                    <meta name="robots" content="noindex"/>
                </Helmet>}
                <Suspense fallback={<Loader backgroundColor={'white'}/>}>
                    <Router>
                        {(this.props.screenView === "loading" || isDisplayProfile) && (
                            <Switch>
                                <Route
                                  exact={true}
                                  path={"/create-subscription"}
                                  render={(props) => (
                                    <MainGuard {...{...props}}>
                                        <CreateSubscription />
                                    </MainGuard>
                                  )}
                                />
                                <Route
                                    exact={true}
                                    path={"/settings"}
                                    render={(props) => (
                                        <MainGuard {...{...props, roleControls: false}}>
                                            <Settings/>
                                        </MainGuard>
                                    )}
                                />
                                <Route
                                    exact={true}
                                    path={"/"}
                                    render={(props) => (
                                        <OktaWrapper {...{ ...props }}>
                                            <MainGuard {...{...props, roleControls: false, redirect: 'bubble'}}>
                                                <HomePage/>
                                            </MainGuard>
                                        </OktaWrapper>
                                    )}
                                />
                                <Route
                                    exact={false}
                                    path={"/organizer/preview/:eventId/:page/:step"}
                                    render={(props) => (
                                        <MainGuard {...{...props}}>
                                            <PreviewFlow mode={"preview"}/>
                                        </MainGuard>
                                    )}
                                />

                                <Route
                                    exact={false}
                                    path={"/organizer/:page?/:eventId?/:subPage?"}
                                    render={(props) => {
                                        return <Redirect to={`/m/${props.match.params.eventId || 'null'}/${props.match.params.page}`}/>
                                    }}/>

                                <Route
                                    exact={false}
                                    path={"/m/:eventId/:page?"}
                                    render={(props) => (
                                        <MainGuard {...{...props}}>
                                            <OrganizerFlow/>
                                        </MainGuard>
                                    )}
                                />

                                <Route
                                    exact={false}
                                    path={"/admin"}
                                    render={(props) => (
                                        <MainGuard {...{...props}}>
                                            <AdminFlow/>
                                        </MainGuard>
                                    )}
                                />
                                <Route
                                    exact={true}
                                    path={"/create-event"}
                                    render={(props) => (
                                        <MainGuard {...{...props, roleControls: false}}>
                                            <CreateEvent/>
                                        </MainGuard>
                                    )}
                                />

                                {/* Unprotected Routes: */}
                                <Route
                                    exact={false}
                                    path={"/ticket-canceled/:eventId"}
                                component={TicketCanceled}
                            />
                            <Route
                                exact={false} path={"/email-settings"}
                                    component={EmailSettingsPage}
                                />
                                <Route
                                    exact={false}
                                    path={"/email-preference/:emailCategory"}
                                    component={EmailPreference}
                                />
                                <Route exact={false} path={"/403"} component={ForbiddenPage}/>
                                <Route exact={false} path={"/404"} component={NotFoundPage}/>
                                <Route
                                    exact={false}
                                    path={"/login/callback"}
                                    render={(props) => (
                                        <OktaWrapper {...{ ...props }}>
                                            <LoginCallback/>
                                        </OktaWrapper>
                                    )}
                                />

                                <Route
                                    exact={false}
                                    path={"/login"}
                                    render={(props) => (
                                        <OktaWrapper {...{ ...props }}>
                                            <LoginPage/>
                                        </OktaWrapper>
                                    )}
                                />

                                <Route
                                    exact={false}
                                    path={"/reset-pw"}
                                    component={ResetPasswordPage}
                                />

                                {/* Use Custom Auth: */}
                                <Route exact={false} path={"/check-in"} component={CheckInFlow}/>
                                <Route
                                    exact={false}
                                    path={"/directory"}
                                    component={DirectoryFlow}
                                />
                                <Route
                                    exact={true}
                                    path={"/live-attendee-stats/:event_id?"}
                                    component={LiveAttendeeStatsOrganizerContainers}
                                />

                                {/*Test Webcam Routes: */}
                                <Route
                                    exact={true}
                                    path={"/test-webcam/:event_id?"}
                                    component={DirectWebcamPage}
                                />

                                {/* Live Event Routes: */}
                                <Route
                                    exact={true}
                                    path={"/live-event/:event_id/:id?"}
                                    component={LoadingContainer}
                                />
                                <Route
                                    exact={false}
                                    path={"/event"}
                                    render={(props) => (
                                        <OktaWrapper {...{ ...props }}>
                                            <EventRegistrationContainer/>
                                        </OktaWrapper>
                                    )}
                                />
                                <Route
                                    exact={false}
                                    path={"/faq/:event_id"}
                                    component={Faq}
                                />
                                <Route
                                    exact={false}
                                    path={"/record-kickoff-video/:event_hash"}
                                    component={SeparatePageForNewRecordVideo}
                                />
                                <Route
                                    exact={false}
                                    path={"/record-wrapup-video/:event_hash"}
                                    component={SeparatePageForNewRecordVideo}
                                />
                                {/*<Route*/}
                                {/*    exact={false}*/}
                                {/*    path={"/new-video-recorder/:event_hash"}*/}
                                {/*    component={SeparatePageForNewRecordVideo}*/}
                                {/*/>*/}
                                {/*<Route*/}
                                {/*    exact={true}*/}
                                {/*    path={"/:version/:event_id/:user_id"}*/}
                                {/*    component={LoadingContainer}*/}
                                {/*/>*/}
                                <Route
                                    exact={true}
                                    path={"/room/:id"}
                                    render={(props) => (
                                        <MainGuard {...{ ...props }}>
                                            <VideoContainer {...{ ...props }}/>
                                        </MainGuard>
                                    )}
                                />
                                <Route
                                    exact={false}
                                    path={"/webinar"}
                                    render={(props) => (
                                        <MainGuard {...{ ...props }}>
                                            <Webinar {...{ ...props }}/>
                                        </MainGuard>
                                    )}
                                />
                                <Route
                                    exact={true}
                                    path={"/wrapup/:eventId"}
                                    component={WrapupContainer}
                                />
                                <Route
                                    exact={true}
                                    path={"/mux-room/:streamName/:authToken"}
                                    component={MuxRoom}
                                />
                            </Switch>
                        )}

                        <Suspense fallback={<Loader backgroundColor={"transparent"} showLoader={false}/>}>
                            {!this.props.isPreviewMode && (this.props.allDataProvided || isDisplayProfile) && (
                                <Sidebar show={screenView !== "loading" || isDisplayProfile}/>
                            )}
                        </Suspense>

                        <TransitionGroup>
                            {(screenView === "video" || screenView === "survey") && (
                                <CSSTransition
                                    classNames="video"
                                    timeout={{ enter: 1000, exit: 1000 }}
                                >
                                    <VideoContainer/>
                                </CSSTransition>
                            )}
                        </TransitionGroup>

                        <Suspense fallback={<Loader backgroundColor={"transparent"} showLoader={false}/>}>
                            <TransitionGroup>
                                {screenView === "break" && (
                                    <CSSTransition
                                        classNames="break"
                                        timeout={{ enter: 1000, exit: 1000 }}
                                    >
                                        <BreakContainer/>
                                    </CSSTransition>
                                )}
                                {screenView === "holding" && (
                                    <CSSTransition
                                        classNames="holding"
                                        timeout={{ enter: 1000, exit: 1000 }}
                                    >
                                        <HoldingContainer/>
                                    </CSSTransition>
                                )}
                            </TransitionGroup>
                        </Suspense>

                        <Suspense fallback={<Loader backgroundColor={"transparent"} showLoader={false}/>}>
                            {this.showRightFlyIn() && <NewMeetPopup/>}
                            {(window.location.pathname.indexOf("check-in") !== -1 ||
                                window.location.pathname.indexOf("live-event") !== -1) && (
                                <FishbowlHandler/>
                            )}
                            {this.showFishbowlPopup() && (
                                <FishbowlPopup
                                    currentView={screenView}
                                    fishbowlView={this.props.fishbowlView}
                                />
                            )}
                            {this.showSurveyPopup() && <SurveyPopup/>}

                            {window.location.pathname.indexOf("live-event") !== -1 &&
                                !this.props.showNextPartnerBioPopup &&
                                !this.showSurveyPopup() &&
                                <EventBreaks />
                            }
                        </Suspense>
                    </Router>
                </Suspense>
            </Container>
        );
    }
}

export default connect(
    (store) => ({
        initLogoutOtherBrowser: store.home.initLogoutOtherBrowser,
        showGreenScreen: store.controller.showGreenScreen,
        accessGrantedErrorBlock: store.controller.accessGrantedErrorBlock,
        screenView: store.controller.screenView,
        allDataProvided: store.controller.allDataProvided,
        containerLoaded: store.controller.containerLoaded,
        fishbowlView: store.controller.fishbowlView,
        showNextSessionPopup: store.controller.showNextSessionPopup,
        showNextPartnerBioPopup: store.controller.showNextPartnerBioPopup,
        nextPartnerBioPopupHided: store.controller.nextPartnerBioPopupHided,
        showRightFlyInPopup: store.controller.showRightFlyInPopup,
        showFishbowlPopup: store.controller.showFishbowlPopup,
        showSurveyPopup: store.controller.showSurveyPopup,
        currentEvent: store.controller.currentEvent,
        currentSession: store.controller.currentSession,
        needShowSurveyPopup: store.controller.needShowSurveyPopup,
        partnerConnected: store.room.partnerConnected,
        joinIsClicked: store.room.joinIsClicked,
        endCallClicked: store.room.endCallClicked,
        nextSessionVariant: store.controller.nextSessionVariant,
        needShowRightFlyin: store.controller.needShowRightFlyin,
        sessionHasBeenExtended: store.controller.sessionHasBeenExtended,
        fishbowlPartyView: store.fishbowl.fishbowlPartyView,
        fishbowlButtonSelected: store.fishbowl.fishbowlButtonSelected,
        currentUser: store.users.currentUser,
        partnersBioProgressesFetched: store.users.partnersBioProgressesFetched,
        eventRegistration: store.checkin.eventRegistration,
        availableToMatch: store.checkin.availableToMatch,
        stepsCount: store.checkin.steps.length,
        failed: store.test.failed,
        inProgress: store.test.inProgress,
        success: store.test.success,
        differenceBetweenServerAndClientTime: store.controller.differenceBetweenServerAndClientTime,
        twilioRoomInstance: store.twilio.roomInstance,
        showFoundRightFlyIn: store.controller.showFoundRightFlyIn,
        isDisplayProfile: store.controller.isDisplayProfile,
        isPreviewMode: store.preview.isPreviewMode,
    }),
    {
        showNextSessionPopupAction,
        showRightFlyInPopupAction,
        showFishbowlPopupAction,
        logging,
        userSeenNextSessionPopupAction,
        setPartnersBioProgressesAction,
        setTargetJoinPopupAction,
        setLoggedInSystemAction,
        saveUserAction,
        setAccountAction,
        getCurrentEventSessionAction,
        getSystemSettingsAction,
    }
)(App);
