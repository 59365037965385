import React from 'react';
import clsx from 'clsx';
import styled from "styled-components";

import WarningIcon from '../../icons/WarningIcon';
import InfoIcon from '../../icons/InfoIcon';
import ErrorIcon from '../../icons/ErrorIcon';


const SnackbarContainer = styled.div`
  position: fixed;
  top: 24px;
  left: auto;
  right: 24px; 
  display: ${pr => pr.open ? 'flex' : 'none'};
  z-index: 1400;
`;

const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 400px;
    min-height: 50px;
    background: white;
    padding: 1em;
    border-radius: 3px;
    box-shadow: 0 12px 24px 4px rgba(40,42,43,0.2);
    
    @media screen and (max-width: 737px), screen and (max-height: 500px) {
      width: 100%;
    }
    
  .contentContainer {
    display: flex;
    line-height: 1.8;
  }
  
  .iconContainer {
    display: flex;
    padding: 0 1.3em 0 0.3em;
    transform: translateY(3px);
  }
  
  .headline {
    font-weight: bold;
  }
  
  .error {
    border-left: 4px solid #D61F1F;
  }
  
  .warning {
    border-left: 4px solid #E46216;
  }
  
  .info {
    border-left: 4px solid #0263e0;
  }
`;

const CloseBtn = styled.div`
  position: absolute;
  top: 5px;
  right: 15px;
  cursor: pointer;
  font-size: 24px;
  color: rgb(153, 153, 153);

  &:hover {
    color: rgb(115, 115, 115);
  }

  &:focus {
    color: rgb(115, 115, 115);
    font-weight: bold;
  }

  &:active {
    color: rgb(115, 115, 115);
    font-weight: bold;
  }
`;

export default function Snackbar({ headline, message, variant, open, handleClose }) {
  return (
    <SnackbarContainer open={open}>
      <Container
        className={clsx({
          error: variant === 'error',
          warning: variant === 'warning',
          info: variant === 'info',
        })}
      >
        <div className={'contentContainer'}>
          <div className={'iconContainer'}>
            {variant === 'warning' && <WarningIcon />}
            {variant === 'error' && <ErrorIcon />}
            {variant === 'info' && <InfoIcon />}
          </div>
          <div>
            <span className={'headline'} >
              {headline}
            </span>
            <span>
              {' '}
              {message}
            </span>
          </div>
        </div>
        <div>
          {handleClose && (
            <CloseBtn onClick={handleClose}>
              X
            </CloseBtn>
          )}
        </div>
      </Container>
    </SnackbarContainer>
  );
}
