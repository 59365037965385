import React from "react";
import clsx from "clsx";
import styled from "styled-components";

import NoneIcon from "../../../icons/NoneIcon";
import BlurIcon from "../../../icons/BlurIcon";
import { getDataOptions } from "../../../../../utils/helpers";


const Thumb = styled.div`
    & {
      margin: 5px;
      width: calc(50% - 10px);
      display: flex;
      position: relative;
     
      &::after {
        content: "";
        padding-bottom: 55.5%;
      }
    }
    
    svg {
      width: 50px;
      height: 50px;
    }
    svg>path {
      fill: #707070;
    }
    
    .thumbContainer {
      width: 100%;
    }
    
    .thumbIconContainer {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border: solid #707070;
      &.selected {
        border: solid #027AC5;
        & svg {
          color: #027AC5;
        }
      }
    }
    
    .thumbIcon {
      height: 50px;
      width: 50px;
      color: #707070;
      &.selected {
        color: #027AC5;
      }
    }
    
    .thumbImage {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      object-fit: cover;
      border-radius: 10px;
      border: solid #707070;
      &:hover {
        cursor: pointer;
        & svg {
          color: #027AC5;
        }
        
        & .thumbOverlay {
          visibility: visible;
        }
      }
      
      &.selected {
        border: solid #027AC5;
        & svg {
          color: #027AC5;
        }
      }
    }
    
    .thumbOverlay {
      user-select: none;
      top: 0;
      position: absolute;
      color: transparent;
      padding: 20px;
      font-size: 14px;
      font-weight: bold;
      width: 100%;
      height: 100%;
      cursor: pointer;
      border-radius: 10px;
      transition: .3s background-color;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: rgba(95, 93, 128, 0.6);
        color: white;
      }
    }
`;

export default function BackgroundThumbnail({ backgroundSettings, setBackgroundSettings, thumbnail, imagePath, name, index }) {
  const isImage = thumbnail === 'image';

  const thumbnailSelected = isImage
    ? backgroundSettings.index === index && backgroundSettings.type === 'image'
    : backgroundSettings.type === thumbnail;

  const icons = {
    none: NoneIcon,
    blur: BlurIcon,
    image: null,
  };

  const ThumbnailIcon = icons[thumbnail];

  return (
      <Thumb>
        <div
          className={'thumbContainer'}
          data-trackable={
              getDataOptions({
                  view: "VirtualBackground",
                  type: "image",
                  label: name
              })
          }
          onClick={() =>
            setBackgroundSettings({
              type: thumbnail,
              index: index,
            })
          }
        >
          {ThumbnailIcon ? (
            <div className={clsx('thumbIconContainer', { selected: thumbnailSelected })}>
              <ThumbnailIcon className={'thumbIcon'} />
            </div>
          ) : (
            <img className={clsx('thumbImage', { selected: thumbnailSelected })} src={imagePath} alt={name} />
          )}
          <div className={'thumbOverlay'}>{name}</div>
        </div>
      </Thumb>
  );
}
