import types from '../constans'
import axios from "axios";
import {getProfileColorHex, sanitizeInfo, getInstanceAxios} from '../../utils/helpers';
import config from "../../utils/config";
import {getSystemToken, setSystemToken} from "../../utils/AuthManager";
import {setDisconnectedAction} from "../controller/actions";
import CryptoJS from "crypto-js";
import publicIp from "public-ip";
import DetectRTC from "detectrtc";
import { setCheckinReadyAction, setUserLoggedAction } from "../checkin/actions";
import { setLoggedInSystemAction } from "../registration/actions";

export const setUserDefaultAccount = (accountId) => ({ type: types.SET_USER_DEFAULT_ACCOUNT, accountId});
export const setMicOnlyModeAction = (isMicOnly = true) => ({ type: types.SET_MIC_ONLY_MODE, isMicOnly});
export const setCurrentUserAction = (user) => ({ type: types.SET_CURRENT_USER, user: sanitizeInfo(user) });
export const setOtherUserAction = (user) => ({ type: types.SET_OTHER_USER, user: sanitizeInfo(user) });
export const setOtherUserBAction = (user) => ({ type: types.SET_OTHER_USER_B, user: sanitizeInfo(user) });
export const setNextPartnerAction = (user) => ({ type: types.SET_NEXT_PARTNER, user: sanitizeInfo(user) });
export const unsetNextPartnerAction = () => ({ type: types.UNSET_NEXT_PARTNER});
export const setInvitedUserAction = (invitedUser) => {
    if (invitedUser === null) {
        return {
            type: types.SET_INVITED_USER,
            user: {
                userId: '',
                firstName: '',
                lastName: '',
                title: '',
                company: '',
                profileColorHex: '',
                profilePhoto: '',
                fromHolding: false
            }
        };
    }
    const {user, first_name, last_name, title, company, profile_color_hex, profile_photo, from_holding} = invitedUser;
    const userData = sanitizeInfo({
        userId: user,
        firstName: first_name,
        lastName: last_name,
        title,
        company,
        profileColorHex: getProfileColorHex(profile_color_hex),
        profilePhoto: profile_photo,
    });

    return {
        type: types.SET_INVITED_USER,
        user: {...userData, fromHolding: from_holding}
    };
};

export const setPreviousUsersAction = (users) => ({ type: types.SET_PREVIOUS_USERS, previousUsers: users });
export const setNextUserAction = (users) => ({ type: types.SET_NEXT_USERS, nextUsers: users });


export const getAccountCustomization = (accountCustomization) => {
    return {
        type: types.SET_ACCOUNT_CUSTOMIZATION,
        accountCustomization: {
            logo_on_light: accountCustomization ? accountCustomization.logo_on_light : '',
            logo_on_dark: accountCustomization ? accountCustomization.logo_on_dark : '',
            domain: accountCustomization ? accountCustomization.domain : '',
            default_background_image: accountCustomization ? accountCustomization.default_background_image : '',
            default_background_image_has_dark_shade: accountCustomization ? accountCustomization.default_background_image_has_dark_shade : '',
            profile_elements: accountCustomization ? accountCustomization.profile_elements : '',
            primary_button_hex: accountCustomization ? accountCustomization.primary_button_hex : '',
            primary_button_click_hex: accountCustomization ? accountCustomization.primary_button_click_hex : '',
            primary_button_disabled_hex: accountCustomization ? accountCustomization.primary_button_disabled_hex : '',
            primary_button_hover_hex: accountCustomization ? accountCustomization.primary_button_hover_hex : '',
            primary_button_text_hex: accountCustomization ? accountCustomization.primary_button_text_hex : '',
            loading: false
        }
    }
};

export const saveNotes = (data, isSidebar = true) => async (dispatch) => {
    const endpoint = data.email_key ? `${config.NODE_API_URL}/users/wrapup/note` : `${config.NODE_API_URL}/users/note`;
    const response = await getInstanceAxios().post(endpoint, data, {crossDomain: true});
    if (response.data.status === 200) {
        if (isSidebar) {
            dispatch({
                newNotes: response.data.newNotes,
                type: types.SAVE_NOTES,
            });
        }
        return true;
    } else {
        return false;
    }
};

export const resetPartnersBioProgressesFetchedAction = () => ({ type: types.RESET_PARTNER_BIO_PROGRESSES_FETCHED });

export const setPartnersBioProgressesAction = (partnersBioProgress) => (dispatch) => {
    dispatch({ type: types.SET_PARTNER_BIO_PROGRESSES, partnersBioProgress });
};
export const setPartnerCompletePriorCallAction = (userId) => (dispatch) => {
    dispatch({ type: types.SET_PARTNER_COMPLETE_PRIOR_CALL, userId });
};
export const setPartnerCompleteSurveyAction = (userId) => (dispatch) => {
    dispatch({ type: types.SET_PARTNER_COMPLETE_SURVEY, userId });
};
export const setPartnerCompleteJoiningAction = (userId, joined) => (dispatch) => {
    dispatch({ type: types.SET_PARTNER_COMPLETE_JOINING, userId, joined });
    dispatch(setDisconnectedAction(false, {
        network: false,
        leftActive: false,
        endCall: false,
        changeDevice: false
    }));
};
export const setPartnerCompleteMatchesAction = (userId) => (dispatch) => {
    dispatch({ type: types.SET_PARTNER_COMPLETE_MATCHES, userId });
};

export const clearInvoice = () => ({type: types.CLEAR_INVOICE});

export const setUsersStore = (store) => ({ type: types.SET_USERS_STORE, store });

export const savePageView = (label, view) => async (dispatch) => {
    dispatch(saveUserAction({type: 'page-view', label, view, target: {}}))
};

export const saveLogStreamIsNull = (label) => async (dispatch) => {
    dispatch(saveUserAction({type: 'stream-is-null', label, target: {}}))
};

export const saveUserAction = ({view, type, label, destination, description, target={}, page = document.location.href}) => async (dispatch, getState) => {
    const {controller: {currentEvent: {eventId}}, users: {currentUser: {userId}}} = getState();
    const ip = await publicIp.v4();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const detectRTC = window.detectRtcData || DetectRTC;
    const data = {
        timezone, page, view, type, description,
        local_time: Date.now(),
        label: label || target.innerText.replace(/[^\w\s]/gi, ''),
        destination: target.href || destination,
        user_id: userId,
        event_id: eventId,
        ip: ip,
        os_name: detectRTC ? detectRTC.osName : 'n/d',
        os_version: detectRTC ? detectRTC.osVersion : 'n/d',
        browser: detectRTC ? detectRTC.browser.name : 'n/d',
        browser_version: detectRTC ? detectRTC.browser.version : 'n/d'
    };

    await getInstanceAxios().post(`${config.NODE_API_URL}/users/main-activity`, {data});
};

export const setDatingToken = async (userId, eventId) => {
    let jwtToken = getSystemToken('run_as_jwt_token') || getSystemToken('jwt_token');
    if (!jwtToken && userId && eventId) {
        // Encrypt
        const authToken = CryptoJS.AES.encrypt(userId, config.CHECK_IN_SECRET).toString();
        const {data} = await axios.post(`${config.NODE_API_URL}/users/auth-dating`, {
            authToken, eventId
        }, {crossDomain: true});

        if (data && data.jwtToken) setSystemToken('jwt_token', data.jwtToken);
        if (userId) setSystemToken('user_id', userId);
    }
};

export const clearUserState = () => async (dispatch) => {
    dispatch({type: types.SET_CURRENT_USER, user:{}});
    dispatch(setUserLoggedAction(false));
    dispatch(setCheckinReadyAction(false));
    dispatch(setLoggedInSystemAction(false));
};

export const setInitializedLogrocketAction = () => (dispatch) => {
    dispatch({ type: types.SET_INITIALIZED_LOGROCKET });
};

export const setIdentifiedLogrocketAction = () => (dispatch) => {
    dispatch({ type: types.SET_IDENTIFIED_LOGROCKET });
};
