import types from "../constans";
import config from "../../utils/config";
import { setCurrentUserAction } from "../users/actions";
import { getInstanceAxios, prepareEventRegistrationData, prepareUserData } from "../../utils/helpers";
import { setEventRegistrationInfoAction, setEventRegistrationsAction } from "../checkin/actions";
import {
    setAttendingEventRegistrationsCount,
    setLoading,
    setOpenSidebarAction,
    setSponsorCount
} from "../organizer/actions";
import { setAccountAction } from "../home/actions";
import { setCurrentEventAction } from "../controller/actions";

export const setAddBulkAttendeesAction = (addedBulkAttendees) => ({type: types.ADD_BULK_ATTENDEES, addedBulkAttendees});
export const setLoadingAction = (isLoading) => ({type: types.SET_LOADING, isLoading});
export const setLoggedInSystemAction = (loggedInSystem) => ({type: types.SET_LOGGED_IN_SYSTEM, loggedInSystem});
export const setRegistrationStepAction = (step) => ({type: types.SET_REGISTRATION_STEP, step});
export const setSelectedTicketAction = (ticket) => {
    localStorage.setItem('selectedTicket', JSON.stringify(ticket || {}));
    return {type: types.SET_SELECTED_TICKET, ticket}
};
export const setCreateRegistrationOnFlyAction = (flag) => ({type: types.SET_CREATE_REGISTRATION_ON_FLY, flag});

export const showRegistrationTicketsPopupAction = (visible) => ({type: types.SHOW_REGISTRATION_TICKETS_POPUP, visible});
export const setErrorMessage = (errorMessage) => ({type: types.SET_ERROR_MESSAGE, errorMessage});
export const setSuccessMessage = (successMessage) => ({type: types.SET_SUCCESS_MESSAGE, successMessage});

export const hideRegistrationTicketsPopupAction = (destination = null, callback = null) => (dispatch) => {
    dispatch({type: types.HIDE_REGISTRATION_TICKETS_POPUP});
    setTimeout(() => {
        dispatch(showRegistrationTicketsPopupAction(false));
        if (destination) {
            dispatch(setRegistrationStepAction(destination));
            if (callback) callback();
        }
    }, 500);
};

export const registrationCheckUserAction = ({eventId, token}) => async (dispatch) => {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/events/auth/${eventId}`, {token}, {crossDomain: true});

    if (response.data.status !== 200) return false;
    const user = response.data.user ? prepareUserData(response.data.user) : null;
    if (user) dispatch(setCurrentUserAction(user));
    if (response.data.eventRegistration) dispatch(setEventRegistrationInfoAction(prepareEventRegistrationData(response.data.eventRegistration)));

    return user;
};

export const wrapupCheckUserAction = ({eventId, token}) => async (dispatch) => {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/events/auth/${eventId}`, {token}, {crossDomain: true});

    const params = {
        loggedIn: false,
        hasRegistration: false,
        user: {}
    };

    if (response.data.status !== 200) return params;

    if (response.data.user) {
        const user = prepareUserData(response.data.user);
        dispatch(setCurrentUserAction(user));
        params.loggedIn = true;
        params.user = user;
    }

    if (response.data.eventRegistration) {
        dispatch(setEventRegistrationInfoAction(prepareEventRegistrationData(response.data.eventRegistration)))
        params.hasRegistration = true;
    }

    return params;
};

export const registrationCreateNewUserAction = ({
                                                    email,
                                                    first_name,
                                                    last_name,
                                                    company,
                                                    title,
                                                    password,
                                                    eventId,
                                                    preferred_language
                                                }) => async () => {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/users/create-new-user`, {
        email,
        first_name,
        last_name,
        company,
        title,
        password,
        eventId, preferred_language
    }, {crossDomain: true});
    return response.data;
};

export const assignTicketToUserAction = ({eventId, userId, ticketId}) => async () => {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/event-registrations/assign-ticket-to-user`, {
        eventId,
        userId,
        ticketId
    }, {crossDomain: true});
    return response.data;
};

export const changeTicketStatusAction = ({eventId, userId, ticketId, status}) => async (dispatch, getState) => {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/event-registrations/change-ticket-status`, {
        eventId,
        userId,
        ticketId,
        status
    }, {crossDomain: true});
    if (response.data.status === 200) {
        const {users: {currentUser}} = getState();
        dispatch(setCurrentUserAction({...currentUser, ticket: response.data.ticket}))
    }
    return response.data;
};

export const sendSystemForgotPasswordEmailAction = ({email, proceed = 'login', event = null}) => async () => {
    const url = `${config.NODE_API_URL}/users/forgot-password`;
    const options = {
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
        }
    };

    try {
        const response = await getInstanceAxios().post(url, {email, proceed, eventId: event}, options);
        return response.data;
    } catch (e) {
        console.error('sendSystemForgotPasswordEmailAction', e);
        return false;
    }
};

export const removeEmailAndTokenResetPassword = (email, token) => async () => {
    const url = `${config.NODE_API_URL}/users/reset-pw/${email}/${token}`;
    const options = {
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
        }
    };

    try {
        await getInstanceAxios().delete(url, options);
        return true;
    } catch (e) {
        console.error('removeEmailAndTokenResetPassword e = ', e);
        return false;
    }
};

export const loginUponResetPassword = ({email, token, password}) => async () => {
    const url = `${config.NODE_API_URL}/users/login-upon-reset-password`;
    const options = {
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
        }
    };
    try {
        const response = await getInstanceAxios().post(url, {email, token, password}, options);
        return response.data;
    } catch (e) {
        console.error('loginUponResetPassword e = ', e);
        return false;
    }
};

export const loginAction = ({email, password}) => async () => {
    const url = `${config.NODE_API_URL}/login`;
    const options = {
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
        }
    };
    try {
        return await getInstanceAxios().post(url, {email, password}, options);
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            return {status: error.response.status, errorMessage: error.response.data.message}
        } else {
            return {status: error.response.status, errorMessage: 'Something went wrong, try again later...'}
        }
    }
};

export const registerAction = ({firstName, lastName, email, password}) => async () => {
    const url = `${config.NODE_API_URL}/register`;
    const options = {
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
        }
    };
    try {
        return await getInstanceAxios().post(url, {firstName, lastName, email, password}, options);
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            return {status: error.response.status, errorMessage: error.response.data.message}
        } else {
            return {status: error.response.status, errorMessage: 'Something went wrong, try again later...'}
        }
    }
};

export const coreAuth = ({hash}) => async () => {
    const url = `${config.NODE_API_URL}/core-auth`;
    const options = {
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
        }
    };
    try {
        return await getInstanceAxios().post(url, {hash}, options);
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            return {status: error.response.status, errorMessage: error.response.data.message}
        } else {
            return {status: error.response.status, errorMessage: 'Something went wrong, try again later...'}
        }
    }
};

export const checkInviteCodeAction = (inviteCode, type) => async (dispatch) => {
    const url = type === 'account' ? `${config.NODE_API_URL}/accounts/check-account-invite-code` : `${config.NODE_API_URL}/organizer/check-invite`;
    const options = {
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
        }
    };
    try {
        const {data} = await getInstanceAxios().post(url, {inviteCode}, options);
        if (data && data.account) dispatch(setAccountAction({account: data.account}));
        return data;
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            return {status: error.response.status, errorMessage: error.response.data.message}
        } else {
            return {status: error.response.status, errorMessage: 'Something went wrong, try again later...'}
        }
    }
};

export const setLastViewedDirectoryAction = () => async (dispatch, getState) => {
    const {controller: {currentEvent: {eventId}}, users: {currentUser: {userId}}} = getState();
    const localTime = Date.now();

    return await getInstanceAxios().put(`${config.NODE_API_URL}/event-registrations/set-last_viewed_directory/${eventId}/${userId}`,
        {
            localTime
        }, {crossDomain: true});
};

export const makeSponsorAction = (userId, eventId, isSponsor) => async (dispatch, getState) => {
    const {checkin: {eventRegistrations}} = getState();

    const response = await getInstanceAxios().put(`${config.NODE_API_URL}/event-registrations/make-sponsor/${eventId}`, {
        userId, isSponsor
    }, {crossDomain: true});

    if (response.data.status !== 200) return false;

    const targetIndex = eventRegistrations.findIndex((registration) => registration && (registration.event_id === eventId && registration.user_id === userId));

    if (targetIndex !== -1) eventRegistrations[targetIndex] = {
        ...eventRegistrations[targetIndex],
        is_sponsor: isSponsor
    };

    dispatch(setSponsorCount(eventRegistrations.filter(eventRegistration => eventRegistration.is_sponsor).length));
    dispatch(setEventRegistrationsAction([...eventRegistrations]));
    return true;
};

export const cancelAttendeeTicketAction = (userId, eventId, ticketId) => async (dispatch, getState) => {
    const {checkin: {eventRegistrations}} = getState();

    const response = await getInstanceAxios().put(`${config.NODE_API_URL}/event-registrations/cancel-attendee-ticket/${eventId}`, {
        userId, ticketId, isOrganizer: true
    }, {crossDomain: true});

    if (response.data.status !== 200) return false;

    const targetIndex = eventRegistrations.findIndex((registration) => registration && (registration.event_id === eventId && registration.user_id === userId));

    if (targetIndex !== -1) eventRegistrations[targetIndex] = response.data.eventRegistration;

    dispatch(setEventRegistrationsAction([...eventRegistrations]));
    return true;
};

export const setVipMatchingAction = (userId, eventId, isVipMatching) => async (dispatch, getState) => {
    const {checkin: {eventRegistrations}} = getState();

    const response = await getInstanceAxios().put(`${config.NODE_API_URL}/event-registrations/set-vip-matching/${eventId}`, {
        userId, isVipMatching: !Number(isVipMatching)
    }, {crossDomain: true});

    if (response.data.status !== 200) return false;

    const targetIndex = eventRegistrations.findIndex((registration) => registration && (registration.event_id === eventId && registration.user_id === userId));

    if (targetIndex !== -1) eventRegistrations[targetIndex] = {
        ...eventRegistrations[targetIndex],
        vip_matching: !Number(isVipMatching)
    };

    dispatch(setEventRegistrationsAction([...eventRegistrations]));
    return true;
};

export const setOrganizerMeetChoiceAction = (choice, currentUserId, userId) => async (dispatch, getState) => {
    const {controller: {currentEvent: {eventId}}, users: {currentUser}, checkin: {eventRegistrations}} = getState();
    console.log({
        choice,
        eventId,
        currentUserId,
        userId,
        organizerId: currentUser.userId
    });

    dispatch(setLoading(true));
    const response = await getInstanceAxios().put(`${config.NODE_API_URL}/event-registrations/set-organizer-meet-users`, {
        eventId,
        choice,
        otherUserId: userId,
        userId: currentUserId,
        organizerId: currentUser.userId
    }, {
        crossDomain: true
    });

    if (response.data.status !== 200) {
        dispatch(setLoading(false));
        return false
    }

    const {data: {registration: {user_user_preferences}}} = response;

    const targetIndex = eventRegistrations.findIndex((registration) => registration && (registration.event_id === eventId && registration.user_id === currentUserId));

    if (targetIndex !== -1) eventRegistrations[targetIndex] = {
        ...eventRegistrations[targetIndex],
        user_user_preferences
    };

    dispatch(setEventRegistrationsAction([...eventRegistrations]));
    dispatch(setOpenSidebarAction(true, 'suggestMatches', {
        user: currentUserId,
        userUserPreferences: user_user_preferences
    }));
    dispatch(setLoading(false));
    return true;
};

export const organizerAddAttendeeAction = ({
                                               email,
                                               firstName,
                                               lastName,
                                               title,
                                               company,
                                               ticket,
                                               sendConfirmation,
                                               userEventTags
                                           }) => async (dispatch, getState) => {
    try {
        const {controller: {currentEvent: {eventId}}, checkin: {eventRegistrations}} = getState();

        dispatch(setLoading(true));
        const response = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/organizer-add-attendee`, {
            eventId, email, firstName, lastName, title, company, ticket, sendConfirmation, userEventTags
        }, {
            crossDomain: true
        });

        if (response.data.status !== 200) {
            dispatch(setLoading(false));
            return response.data;
        }

        const {data: {registration}} = response;

        const targetIndex = eventRegistrations.findIndex((reg) => reg && (reg.event_id === eventId && reg.user_id === registration.user_id));
        if (targetIndex !== -1) {
            eventRegistrations[targetIndex] = {...eventRegistrations[targetIndex], ...registration};
        } else {
            eventRegistrations.unshift(registration);
        }
        dispatch(setAttendingEventRegistrationsCount(eventRegistrations.length));
        dispatch(setEventRegistrationsAction([...eventRegistrations]));
        dispatch(setAddBulkAttendeesAction(true));
        dispatch(setLoading(false));
        setTimeout(() => dispatch(setOpenSidebarAction(false)), 500);

        return true;
    } catch (e) {
        if (e.response && e.response.status === 429) {
            return {
                status: 400,
                message: "Too many requests, try again later..."
            };
        }
        return {
            status: 500,
            message:"Something went wrong, try again later..."
        };
    }
};

export const bulkAddAttendeesAction = (data, sendConfirmation) => async (dispatch, getState) => {
    const {controller: {currentEvent: {eventId}}} = getState();
    dispatch(setLoading(true));
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/bulk-add-attendees`, {
        eventId, data, sendConfirmation
    }, {
        crossDomain: true
    });

    if (response.data.status !== 200) {
        dispatch(setLoading(false));
        return false;
    }

    const {data: {filteredData, registrations}} = response;
    if (registrations && registrations.length) dispatch(setAttendingEventRegistrationsCount(registrations.length));
    dispatch(setLoading(false));

    return filteredData;
};

export const bulkAddAttendeesSendEmailAction = (data) => async (dispatch, getState) => {
    const {controller: {currentEvent: {eventId}}} = getState();

    dispatch(setLoading(true));
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/bulk-add-attendees-send-email`, {
        eventId, data
    }, {
        crossDomain: true
    });

    if (response.data.status !== 200) {
        dispatch(setLoading(false));
        return false;
    }

    // const {data: {registrations}} = response;
    // dispatch(setEventRegistrationsAction([...registrations]));

    dispatch(setLoading(false));

    // setTimeout(()=>dispatch(setOpenSidebarAction(false)), 500);

    return true;
};

export const bulkAddAttendeesTagAction = (eventTagId, isMeetTag, data) => async (dispatch, getState) => {
    const {controller: {currentEvent: {eventId}}} = getState();

    dispatch(setLoading(true));
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/bulk-add-attendees-tag`, {
        eventId, eventTagId, isMeetTag, data
    }, {
        crossDomain: true
    });

    if (response.data.status !== 200) {
        dispatch(setLoading(false));
        return false;
    }

    dispatch(setLoading(false));

    // setTimeout(()=>dispatch(setOpenSidebarAction(false)), 500);

    return response ? response.data : null;
};

export const organizerReRegisterAttendeeAction = ({userId, eventId, ticketId}) => async (dispatch, getState) => {
    const {checkin: {eventRegistrations}} = getState();

    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/re-register-attendees`, {
        eventId, userId, ticketId
    }, {
        crossDomain: true
    });

    if (response.data.status !== 200) {
        dispatch(setLoading(false));
        return false
    }

    const {data: {registration}} = response;

    const targetIndex = eventRegistrations.findIndex((reg) => reg && (reg.event_id === eventId && reg.user_id === registration.user_id));
    if (targetIndex !== -1) {
        eventRegistrations[targetIndex] = {...eventRegistrations[targetIndex], ...registration};
    } else {
        eventRegistrations.unshift(registration);
    }

    dispatch(setEventRegistrationsAction([...eventRegistrations]));

    return true;
};

export const organizerRefundAction = ({userId, eventId, ticketId}) => async (dispatch, getState) => {
    const {checkin: {eventRegistrations}} = getState();

    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/refund-canceled-ticket`, {
        eventId, userId, ticketId
    });

    if (response.data.status !== 200) {
        dispatch(setLoading(false));
        return false
    }

    const targetIndex = eventRegistrations.findIndex((reg) => reg && (reg.event_id === eventId && reg.user_id === userId));
    if (targetIndex !== -1) {
        eventRegistrations[targetIndex] = {...eventRegistrations[targetIndex], refund_status: 'rending'};
        dispatch(setEventRegistrationsAction([...eventRegistrations]));
    }

    return true;
};

export const webinarAddUserAction = ({email, firstName, lastName, role, eventId, isHost}) => async (dispatch, getState) => {
    const {currentEvent} = getState().controller;
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/webinar-add-user`, {
        email, firstName, lastName, role, eventId
    });

    if (response.data.status !== 200) {
        return false
    } else {
        const user = {
            ...prepareUserData(response.data.user),
            webinarRole: role
        };
        const dataHosts = [...currentEvent.hosts];
        const dataSpeakers = [...currentEvent.speakers];
        const existingIndexHost = dataHosts.findIndex((userData) => (userData.userId === user.userId))
        const existingIndexSpeaker = dataSpeakers.findIndex((userData) => (userData.userId === user.userId));
        if (existingIndexHost >= 0 && !isHost) {
            dataHosts.splice(existingIndexHost, 1);
            dataSpeakers.push(user);
        } else if (existingIndexSpeaker >= 0 && isHost) {
            dataSpeakers.splice(existingIndexSpeaker, 1);
            dataHosts.push(user);
        } else if ((existingIndexHost < 0 && isHost) || (existingIndexSpeaker < 0 && !isHost)) {
            if (isHost) {
                dataHosts.push(user);
            } else {
                dataSpeakers.push(user);
            }
        }
        dispatch(setCurrentEventAction({
            ...currentEvent,
            hosts: dataHosts,
            speakers: dataSpeakers
        }));
    }
};

export const webinarUpdateUserAction = ({userId, role, eventId, isHost}) => async (dispatch, getState) => {
    const {currentEvent} = getState().controller;
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/webinar-update-user`, {
        userId, role, eventId
    });

    if (response.data.status !== 200) {
        return false
    } else {
        const user = {
            ...prepareUserData(response.data.user),
            webinarRole: role
        };
        const key = isHost ? 'hosts' : 'speakers';
        const keyAnother = isHost ? 'speakers' : 'hosts';
        const newDataArray = currentEvent[key].length ? [...currentEvent[key]] : [];
        const prevDataArray = currentEvent[keyAnother].length ? [...currentEvent[keyAnother]] : [];
        const existingIndex = prevDataArray.findIndex((userData) => (userData.userId === user.userId));
        prevDataArray.splice(existingIndex, 1);
        newDataArray.push(user);
        dispatch(setCurrentEventAction({
            ...currentEvent,
            [key]: newDataArray,
            [keyAnother]: prevDataArray
        }));
    }
};

export const webinarDeleteUserAction = ({userId, eventId, isHost}) => async (dispatch, getState) => {
    const {currentEvent} = getState().controller;
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/webinar-delete-user`, {
        userId, eventId
    });

    if (response.data.status !== 200) {
        return false
    } else {
        const user = {
            ...prepareUserData(response.data.user),
        };
        const key = isHost ? 'hosts' : 'speakers';
        const data = [...currentEvent[key]];
        const existingIndex = data.findIndex((userData) => (userData.userId === userId));
        data.splice(existingIndex, 1);
        dispatch(setCurrentEventAction({
            ...currentEvent,
            [key]: data
        }));

        return user;
    }
};
export const webinarEnabledAction = ({ isLiveKickoff, eventId }) => async (dispatch, getState) => {
    const {currentEvent} = getState().controller;
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/organizers/set-webinar`, {
        isLiveKickoff, eventId
    });

    if (response.data.status !== 200) {
        return false
    } else {
        const hosts = [...currentEvent.hosts];
        const existingIndex = response.data.user ? hosts.findIndex((host) => (host && host.userId === response.data.user.user)) : -1;
        if ((existingIndex < 0 || !hosts.length) && isLiveKickoff) {
            const user = {
                ...prepareUserData(response.data.user),
                webinarRole: 'hostPlusNetwork'
            };
            dispatch(setCurrentEventAction({
                ...currentEvent,
                hosts: [...currentEvent.hosts, user],
                webinarEnabled: isLiveKickoff,
            }));
            return user;
        } else {
            dispatch(setCurrentEventAction({
                ...currentEvent,
                webinarEnabled: isLiveKickoff,
            }));
            return null;
        }

    }
};
