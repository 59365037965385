import types from "../constans";

const initialSettings = {
    trackSwitchOffMode: undefined,
    dominantSpeakerPriority: 'standard',
    bandwidthProfileMode: 'collaboration',
    maxAudioBitrate: '16000',
    contentPreferencesMode: 'auto',
    clientTrackSwitchOffControl: 'auto',
};

const initialState = {
    error: null,
    isFetching: false,
    settings: initialSettings,
    roomType: '',
    roomInstance: null,
    needReInitRoom: false,
    roomName: null,
    audioTrack: null,
    videoTrack: null,
    activeSinkId: 'default',
    immediatelyStopTracks: false,
};

// This inputLabels object is used by ConnectionOptions.tsx. It is used to populate the id, name, and label props
// of the various input elements. Using a typed object like this (instead of strings) eliminates the possibility
// of there being a typo.
export const inputLabels = (() => {
    const target = {};
    for (const setting in initialSettings) {
        target[setting] = setting;
    }
    return target;
})();

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_TWILIO_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case types.SET_TWILIO_IS_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching,
                needReInitRoom: false,
            };
        case types.SET_TWILIO_ACTIVE_SINK_ID:
            return {
                ...state,
                sinkId: action.sinkId,
            };
        case types.SET_TWILIO_SETTINGS:
            return {
                ...state,
                [action.name]: action.value === 'default' ? undefined : action.value,
            };
        case types.SET_TWILIO_ROOM_TYPE:
            return {
                ...state,
                roomType: action.roomType,
                needReInitRoom: false,
            };
        case types.SET_TWILIO_ROOM_INSTANCE:
            return {
                ...state,
                roomInstance: action.roomInstance,
                needReInitRoom: false,
            };
        case types.TWILIO_NEED_RE_INIT_ROOM:
            return {
                ...state,
                needReInitRoom: true,
            };
        case types.SET_TWILIO_ROOM_NAME:
            return {
                ...state,
                roomName: action.roomName,
            };
        case types.SET_TWILIO_AUDIO_TRACK:
            return {
                ...state,
                audioTrack: action.audioTrack
            };
        case types.SET_TWILIO_VIDEO_TRACK:
            return {
                ...state,
                videoTrack: action.videoTrack
            };
        case types.SET_TWILIO_AUDIO_OUTPUT_ID:
            return {
                ...state,
                activeSinkId: action.activeSinkId
            };
        case types.SET_TWILIO_STOP_ALL_TRACK:
            return {
                ...state,
                immediatelyStopTracks: action.value
            };

        default: return state
    }
}
