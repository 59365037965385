import { DEFAULT_VIDEO_CONSTRAINTS, P2P_MOBILE_VIDEO_CONSTRAINTS, P2P_VIDEO_CONSTRAINTS } from "../../constants";
import store from "../../../../store";
import { isMobile } from "../index";

export default function useVideoConfig() {
  const {roomType} = store.getState().twilio;

  let videoConfig = DEFAULT_VIDEO_CONSTRAINTS;
  if (roomType === 'peer-to-peer') {
    videoConfig = isMobile ? P2P_MOBILE_VIDEO_CONSTRAINTS : P2P_VIDEO_CONSTRAINTS;
  }

  return videoConfig;
};
