import types from "../constans";


const initialState = {
    fullScreenWebinar: false,
    participantType: '',
    participantName: '',
    eventName: '',
    isOpenFlyInSidebar: false,
    flyInUserProfile: null,
    loading: false,
    startedTime: null,
    closedWebinar: false,
    streamPlaybackId: null,
    isNeedUnmute: false,
    lastWebinarAction: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_WEBINAR_FULL_SCREEN:
            return {
                ...state,
                fullScreenWebinar: action.value,
            };

        case types.SET_WEBINAR_PARTICIPANT_TYPE:
            return {
                ...state,
                participantType: action.value,
            };
        case types.SET_WEBINAR_PARTICIPANT_NAME:
            return {
                ...state,
                participantName: action.value,
            };
        case types.SET_WEBINAR_EVENT_NAME:
            return {
                ...state,
                eventName: action.value,
            };
        case types.SET_WEBINAR_FLY_IN_USER_PROFILE:
            return {
                ...state,
                flyInUserProfile: action.flyInUserProfile
            }
        case types.SET_WEBINAR_IS_OPEN_FLY_IN_SIDEBAR:
            return {
                ...state,
                isOpenFlyInSidebar: action.isOpenFlyInSidebar
            }

        case types.SET_WEBINAR_SET_LOADING:
            return {
                ...state,
                loading: action.loading
            }

        case types.SET_WEBINAR_SET_STARTED_TIME:
            return {
                ...state,
                startedTime: action.startedTime
            }
        case types.SET_WEBINAR_CLOSED:
            return {
                ...state,
                closedWebinar: action.value
            }
        case types.SET_STREAM_PLAYBACK_ID:
            return {
                ...state,
                streamPlaybackId: action.value
            };
        case types.SET_IS_NEED_UNMUTE:
            return {
                ...state,
                isNeedUnmute: action.value
            };
        case types.SET_IS_LAST_WEBINAR_ACTION:
            return {
                ...state,
                lastWebinarAction: action.value
            };

        default: return state
    }
}
