import React, { useEffect, useRef, useState } from "react";
import Styled from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import useChatContext from "../../hooks/useChatContext/useChatContext";
import ChatInput from "./ChatInput/ChatInputComponent";
import TextMessage from "./TextMessage/TextMessageComponent";
import MediaMessage from "./MediaMessage/MediaMessageComponent";
import store from "../../../../store";

const Chat = ({isWebinar}) => {
    const { isChatWindowOpen, messages, conversation, setIsChatWindowOpen } = useChatContext();
    const messagesContainerDom = useRef();
    const [formattedMessages, setFormattedMessages] = useState([]);

    useEffect(() => {
        if (isChatWindowOpen) {
            Promise.all(messages.map(async message => {
                const { attributes } = await message.getParticipant()
                return {
                    id: message.sid,
                    author: message.author,
                    body: message.body,
                    media: message.media,
                    type: message.type,
                    attributes
                }
            }))
                .then(res => {
                    setFormattedMessages(res);
                    scrollMessagesDown();
                })
        }
    }, [messages, isChatWindowOpen]);


    const scrollMessagesDown = () => {
        const domElem = messagesContainerDom.current;
        if (domElem) {
            domElem.scroll({
                top: parseInt(domElem.scrollHeight)
            });
        }
    };

    return (isChatWindowOpen &&
        <Styled.Wrapper isWebinar={isWebinar}>
            <Styled.ChatHeader>
                <span>Chat</span>
                <FontAwesomeIcon icon={faTimes} onClick={() => {
                    setIsChatWindowOpen(false)
                }} />
            </Styled.ChatHeader>
            <Styled.ChatBodyWrapper>
                {!messages.length && <Styled.EmptyChatDecorator />}
                <Styled.ChatBody>
                    <Styled.MessagesWrap ref={messagesContainerDom}>
                        {formattedMessages.map((message) => {
                            const isMine = message.author.includes(store.getState().users.currentUser.userId);
                            console.log('message.author', message.author);
                            return (
                                <React.Fragment key={message.id}>
                                    {message.type === 'text' && <TextMessage message={message} isMine={isMine} />}
                                    {message.type === 'media' && <MediaMessage media={message.media} />}
                                </React.Fragment>
                            )
                        })}
                    </Styled.MessagesWrap>
                    <ChatInput conversation={conversation} isChatWindowOpen={isChatWindowOpen} />
                </Styled.ChatBody>
            </Styled.ChatBodyWrapper>
        </Styled.Wrapper>
    )
};

export default Chat;
