import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import oneMinuteSound from "../../assets/sounds/2_one_minute_remaining.wav";
import socketModule from "../../utils/socketModule";
import { getSyncDateNow } from "../../utils/helpers";
import fishbowlTypes from "../Popups/FishbowlViewTypes";

import {
    clearAcceptedInviteAction,
    getCurrentUserSession,
    getHeartBeatInfo,
    hideNextPartnerBioPopupAction,
    logging,
    pushUserSessionToStore,
    refreshCurrentUserSessionAction,
    setExtendCallBlockedAction,
    setExtendCallByMinutesAction,
    setExtendCallDirectModeAction,
    setPartyViewAction,
    setSurveySuccessfullyPostedAction,
    setUserSessionPartyViewAction,
    show3rdPartyPopupAction,
    showExtendCallPopupAction,
    showNextPartnerBioPopupAction,
    showSurveyPopupAction,
    submitSurveyFormAction,
    switchFishbowlViewAction,
    userDeclineInviteAction
} from "../../store/controller/actions";

import {
    setInvitedUserAction,
    setMicOnlyModeAction,
    setOtherUserAction,
    setOtherUserBAction,
    setPartnerCompleteJoiningAction,
    setPartnerCompleteMatchesAction,
    setPartnerCompletePriorCallAction,
    setPartnerCompleteSurveyAction
} from "../../store/users/actions";

import {
    clearChoicesAndSelectNextSessionVariantAction,
    myChoiceAction,
    processExtendCall,
    setVideoBothConnectedAction,
    setVideoDidConnectedAction,
    userMadeChoiceAction
} from "../../store/room/actions";

import NextPartnerBio from "../Popups/NextPartnerBio";
import ExtendCallPopup from "../Popups/ExtendCall";
import NewMeet from "../Popups/NewMeet";
import Add3rdUserToCall from "../Popups/Add3rdUserToCall";
import Support from "../TestContainerElements/Support";
import OfferToJoin from "../OfferToJoin";
import TwilioVideo from "../TwilioVideo";
import FadeScreen from "../ContainerElements/FadeScreen";
import LiveMessage from "../Popups/LiveMessage";

const VideoSupportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  color: #fff;
  width: 100%;
  z-index: 1001;
  padding-bottom: 65%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
`;


// TODO:: need refactoring
class VideoContainer extends React.Component {

    _timeouts = {
        heartbeat75: null
    };

    _oneMinuteAudio = new Audio(oneMinuteSound);

    componentWillUnmount() {
        for (let timeout in this._timeouts) {
            if (this._timeouts[timeout] !== null) {
                clearTimeout(this._timeouts[timeout]);
            }
        }
        socketModule.socket.off("3rd-party.invite_accepted");
        socketModule.socket.off("3rd-party.create_session_state");
        socketModule.socket.off("3way_call.user_moved_to_next_room");
    }

    componentDidUpdate(prevProps, nextState) {
        if (!prevProps.containerLoaded && this.props.containerLoaded) {
            const hasClickedEndCall = window.sessionStorage.getItem(`clicked-end-call`) === this.props.currentSession.roomNumber;
            const hasPartnerClickedEndCall = window.sessionStorage.getItem(`partner-clicked-end-call`) === this.props.currentSession.roomNumber;
            const nextUsers = [this.props.otherUser, this.props.otherUserB].filter(user => user.userId);

            if (!hasClickedEndCall && !hasPartnerClickedEndCall && !this.props.partnerConnected && this.props.screenView === "video" && nextUsers.length) {
                this.props.showNextPartnerBioPopupAction(true);
            }
            if (this.props.screenView === "survey") this.props.showSurveyPopupAction(true);

            this.props.setSurveySuccessfullyPostedAction(true);
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.props.screenView === "video" && nextProps.screenView === "survey") {
            setTimeout(() => {
                this.props.showSurveyPopupAction(true);
            }, 500);
            return false;
        }
        if (this.props.screenView === "survey" && nextProps.screenView === "video") {
            this.initHeartBeat75();
            return false;
        }
        return true;

    }

    async componentDidMount() {
        console.warn("componentDidMount");
        document.body.style.backgroundColor = "#000";
        if (!this.props.directMode) await this.props.refreshCurrentUserSessionAction();
        if (!this.props.location || this.props.location.pathname.search("/room/") === -1) {
            //disable browser back button
            window.history.pushState(null, null, window.location.href);
            window.onpopstate = function () {
                window.history.go(1);
            };
        }
        this.props.setPartyViewAction(null);

        if (window.location.pathname.includes("/room/")) {
            this.props.setMicOnlyModeAction(true);
        }

        socketModule.socket.emit("connect_to_self_room", {
            eventId: this.props.currentEvent.eventId,
            userId: this.props.currentUser.userId
        });

        socketModule.socket.on("extend_call_choice", async ({ userId, variant, options }) => {
            if (options.initial && options.directMode) {
                if (userId !== this.props.currentUser.userId) return;
                this.props.setExtendCallDirectModeAction();
                this.props.setExtendCallByMinutesAction(options.extendCallByMinutes);
                this.props.myChoiceAction("extend");
                this.props.showExtendCallPopupAction(true);
                await this.props.processExtendCall();
                if (options.initial) setTimeout(() => {
                    this.props.clearChoicesAndSelectNextSessionVariantAction();
                }, 10 * 1000);
                return;
            }
            if (options.extendCallByMinutes) {
                this.props.setExtendCallByMinutesAction(options.extendCallByMinutes);
            }
            if (options.directMode && options.userId === this.props.currentUser.userId) {
                this.props.setExtendCallByMinutesAction(options.extendCallByMinutes);
            }
            if (options.initial) setTimeout(() => {
                this.props.clearChoicesAndSelectNextSessionVariantAction();
            }, 40 * 1000);

            this.props.userMadeChoiceAction(userId, variant);
            if (!this.props.callAlreadyExtended && variant === "extend") this.props.showExtendCallPopupAction(true);
        });

        socketModule.socket.on("extend_call.block", async () => {
            this.props.setExtendCallBlockedAction(true);
            this.props.showExtendCallPopupAction(false);
        });

        socketModule.socket.on("3rd-party.ask_to_invite", async ({ invitedUser }) => {
            const { sessionEndTime } = this.props.currentSession;
            const now = new Date(getSyncDateNow());
            const future = now.setSeconds(now.getSeconds() + 240);
            if (Date.parse(sessionEndTime) > future) {
                this.props.setInvitedUserAction(invitedUser);
                this.props.setPartyViewAction("ask");
                await this.props.setUserSessionPartyViewAction("ask");
                this.props.show3rdPartyPopupAction(true);
                this.props.logging({ message: `See 3rd party popup with party view <b>ask</b> | <small> VideoContainer(96) </small>` });
            }
        });

        socketModule.socket.on("3rd-party.invite_accepted", ({ userId }) => {
            if (this.props.currentUser.userId === userId) {
                socketModule.socket.emit("3rd-party.all_accepted", {
                    eventId: this.props.currentEvent.eventId,
                    minPerSession: this.props.currentEvent.minPerSession,
                    roomNumber: this.props.currentSession.roomNumber,
                    invitedUserId: this.props.invitedUser.userId,
                    fromHolding: this.props.invitedUser.fromHolding
                });
            }
        });

        socketModule.socket.on("3rd-party.create_session_state", ({ isCreated }) => {
            if (isCreated) {
                this.props.setPartyViewAction("inviting");
            } else {
                this.props.setPartyViewAction("found");
                setTimeout(() => {
                    this.props.show3rdPartyPopupAction(false);
                    this.props.setPartyViewAction(null);
                    this.props.setInvitedUserAction(null);
                    this.props.clearAcceptedInviteAction();
                }, 4000);
            }
        });

        socketModule.socket.on("3rd-party.invite_declined", ({ userId }) => {
            this.props.userDeclineInviteAction(userId);
            this.props.setPartyViewAction(this.props.currentUser.userId === userId ? "iDeclined" : "oDeclined");
        });

        socketModule.socket.on("3rd-party.fishbowl-user_cancel_search", () => {
            this.props.setPartyViewAction("oPartnerFound");
        });

        socketModule.socket.on("user-session_updated", async () => {
            const userSession = await this.props.getCurrentUserSession();
            if (userSession) {
                await this.props.pushUserSessionToStore(userSession);
            }
        });

        socketModule.socket.on("play-notification", async ({ type }) => {
            const { currentSession: { sessionEndTime } } = this.props;
            switch (type) {
                case "one-minute" :
                    if (this.props.screenView !== "video") return;
                    if (Date.parse(sessionEndTime) - getSyncDateNow() > 60 * 1000) return;
                    this._oneMinuteAudio.volume = 0.3;
                    await this._oneMinuteAudio.play();
                    break;
                default:
                    return;
            }
        });

        socketModule.socket.on("fishbowl.invited_user_moved_to_room", () => this.props.show3rdPartyPopupAction(false));

        socketModule.socket.on("3way_call.user_moved_to_next_room", ({ userId }) => {
            const { currentUser, otherUser, otherUserB, setOtherUserAction, setOtherUserBAction } = this.props;
            if (userId === currentUser.userId) return;
            if (userId === otherUser.userId) setOtherUserAction({ ...otherUser, inRoom: false });
            if (userId === otherUserB.userId) setOtherUserBAction({ ...otherUserB, inRoom: false });
        });

        socketModule.socket.on("usersession.both_user_connected", async () => {
            if (!this.props.currentSession.bothConnected) {
                this.props.logging({
                    message: `<b>Successfully connected with a partner in room ${this.props.currentSession.roomNumber} (both_connected)</b> | <small> VideoContainer(151) </small>`,
                    status: "success"
                });
            }
            await this.props.submitSurveyFormAction({
                event: this.props.currentEvent.eventId,
                user: this.props.currentUser.userId,
                session_number: this.props.currentSession.sessionNumber
            }, "both_connected");
            this.props.setVideoBothConnectedAction();
        });

        socketModule.socket.on("usersession.did_user_connected", async (flag) => {
            this.props.setVideoDidConnectedAction(flag);
        });

        socketModule.socket.on("bio_progress.complete_prior_call", async ({ userId }) => {
            this.props.setPartnerCompletePriorCallAction(userId);
        });
        socketModule.socket.on("bio_progress.complete_survey", async ({ userId }) => {
            this.props.setPartnerCompleteSurveyAction(userId);
        });
        socketModule.socket.on("bio_progress.complete_joining", async ({ userId, joined }) => {
            this.props.setPartnerCompleteJoiningAction(userId, joined);
        });
        socketModule.socket.on("bio_progress.complete_matches", async ({ userId }) => {
            this.props.setPartnerCompleteMatchesAction(userId);
        });
        this.initHeartBeat75();
    }

    initHeartBeat75 = () => {
        // console.warn('DELAY: ', this.getTimeoutDelay(120));
        this._timeouts.heartbeat75 = setTimeout(async () => {
            setTimeout(async () => {
                if (this.props.directMode) return;
                const heartbeat = await this.props.getHeartBeatInfo();

                console.warn("heartbeat from video container 75");
                console.warn({
                    RESULT: !!((this.props.joinIsClicked && heartbeat.videoConnected !== true) && this.props.otherUserB.userId === ""),
                    joinIsClicked: this.props.joinIsClicked,
                    heartbeat: heartbeat.videoConnected,
                    otherUserBUserId: this.props.otherUserB.userId,
                    showFishbowlPopup: this.props.showFishbowlPopup
                });

                if ((this.props.joinIsClicked && heartbeat.videoConnected !== true) && this.props.otherUserB.userId === "") {
                    this.props.hideNextPartnerBioPopupAction();
                    if (!this.props.showFishbowlPopup) {
                        console.warn("FISBOWL SHOWED FROM VIDEO CONTAINER initHeartBeat75", this.props);
                        this.switchFishbowlView(heartbeat);
                    }
                }
            }, 200);
        }, this.getTimeoutDelay(75));

    };

    async UNSAFE_componentWillReceiveProps(props) {
        if (this.props.roomNumber !== props.roomNumber && this._timeouts.heartbeat75) {
            clearTimeout(this._timeouts.heartbeat75);
            this.initHeartBeat75();
        }
    }

    switchFishbowlView = (heartbeat) => {
        const lastSession = this.checkLastSession();
        switch (true) {
            case !this.props.partnerConnected && this.props.fishbowlView !== "break" && this.props.screenView !== "break":
                setTimeout(() => {
                    this.props.switchFishbowlViewAction(fishbowlTypes.VIDEO_NO_SHOW);
                }, 2000);
                break;
            case heartbeat.availableToMet === false :
                this.props.switchFishbowlViewAction(fishbowlTypes.MET_EVERYONE);
                break;
            case lastSession :
                this.props.switchFishbowlViewAction(fishbowlTypes.WRAPUP);
                break;
            case heartbeat.videoCompleted === true :
                this.props.switchFishbowlViewAction(fishbowlTypes.VIDEO_PARTNER_MOVED_ON);
                break;
            case heartbeat.videoCompleted === false :
                this.props.switchFishbowlViewAction(fishbowlTypes.VIDEO_PARTNER_LEFT);
                break;
            default:
                break;
        }
    };

    checkLastSession = () => {
        const { minPerSession, internalEndDateAndTime } = this.props.currentEvent;

        const sessionDuration = minPerSession * 60 * 1000;
        return getSyncDateNow() + sessionDuration > Date.parse(internalEndDateAndTime);
    };

    getTimeoutDelay = (seconds) => {
        const { sessionStartTime } = this.props.currentSession;
        let delay = sessionStartTime ? (Date.parse(sessionStartTime) + seconds * 1000) - getSyncDateNow() : seconds * 1000;
        if (delay < 45000) delay = 45000;
        if (delay > 75000) delay = 75000;
        return delay;
    };

    showExtendCallPopup = () => {
        const { showExtendCallPopup, screenView, partnerConnected } = this.props;
        return showExtendCallPopup && screenView === "video" && partnerConnected;
    };

    render() {
        return (
            <>
                {
                    this.props.accessGrantedErrorBlock &&
                    this.props.showSupport &&
                    <VideoSupportWrapper>
                        <Support/>
                    </VideoSupportWrapper>
                }
                <LiveMessage />
                <TwilioVideo {...{ ...this.props }}/>
                <OfferToJoin/>
                {this.showExtendCallPopup() && <ExtendCallPopup/>}
                {this.props.showFoundRightFlyIn && (<NewMeet moveToRoom={true}/>)}
                {(this.props.show3rdPartyPopup && this.props.partnerConnected) &&
                    <Add3rdUserToCall variant={this.props.partyView}/>}
                {this.props.showNextPartnerBioPopup && !this.props.showSurveyPopup && (<NextPartnerBio/>)}
                <FadeScreen/>
            </>
        );
    }
}

export default connect(
    store => ({
        currentUser: store.users.currentUser,
        invitedUser: store.users.invitedUser,
        otherUser: store.users.otherUser,
        otherUserB: store.users.otherUserB,
        containerLoaded: store.controller.containerLoaded,
        isReady: store.controller.isReady,
        currentEvent: store.controller.currentEvent,
        currentSession: store.controller.currentSession,
        acceptedInvite: store.controller.acceptedInvite,
        show3rdPartyPopup: store.controller.show3rdPartyPopup,
        showFoundRightFlyIn: store.controller.showFoundRightFlyIn,
        showFishbowlPopup: store.controller.showFishbowlPopup,
        fishbowlView: store.controller.fishbowlView,
        showNextPartnerBioPopup: store.controller.showNextPartnerBioPopup,
        showSurveyPopup: store.controller.showSurveyPopup,
        screenView: store.controller.screenView,
        partyView: store.controller.partyView,
        directMode: store.controller.directMode,
        accessGrantedErrorBlock: store.controller.accessGrantedErrorBlock,
        showExtendCallPopup: store.controller.showExtendCallPopup,
        partnerConnected: store.room.partnerConnected,
        joinIsClicked: store.room.joinIsClicked,
        myChoice: store.room.myChoice,
        callAlreadyExtended: store.room.callAlreadyExtended,
        showSupport: store.test.showSupport,
        differenceBetweenServerAndClientTime: store.controller.differenceBetweenServerAndClientTime,
        roomNumber: store.controller.currentSession ? store.controller.currentSession.roomNumber : null
    }),
    {
        userDeclineInviteAction,
        setPartyViewAction,
        show3rdPartyPopupAction,
        showExtendCallPopupAction,
        processExtendCall,
        setInvitedUserAction,
        getCurrentUserSession,
        pushUserSessionToStore,
        setUserSessionPartyViewAction,
        getHeartBeatInfo,
        switchFishbowlViewAction,
        showSurveyPopupAction,
        showNextPartnerBioPopupAction,
        hideNextPartnerBioPopupAction,
        setSurveySuccessfullyPostedAction,
        setVideoBothConnectedAction,
        setVideoDidConnectedAction,
        refreshCurrentUserSessionAction,
        userMadeChoiceAction,
        myChoiceAction,
        setExtendCallBlockedAction,
        setExtendCallDirectModeAction,
        setExtendCallByMinutesAction,
        clearChoicesAndSelectNextSessionVariantAction,
        submitSurveyFormAction,
        setPartnerCompletePriorCallAction,
        setPartnerCompleteSurveyAction,
        setPartnerCompleteJoiningAction,
        setPartnerCompleteMatchesAction,
        logging,
        setOtherUserAction,
        setOtherUserBAction,
        clearAcceptedInviteAction,
        setMicOnlyModeAction
    }
)(VideoContainer);
