import { useCallback, useEffect, useState } from "react";
import useDevices from "../useDevices/useDevices";
import useMediaStreamTrack from "../useMediaStreamTrack/useMediaStreamTrack";
import useVideoContext from "../useVideoContext/useVideoContext";
import useVideoConfig from "../../utils/useVideoConfig/useVideoConfig";

export default function useFlipCameraToggle() {
  const videoConfig = useVideoConfig();
  const { localTracks } = useVideoContext();
  const [supportsFacingMode, setSupportsFacingMode] = useState(false);
  const videoTrack = localTracks.find(track => !track.name.includes('screen') && track.kind === 'video');
  const mediaStreamTrack = useMediaStreamTrack(videoTrack);
  const { videoInputDevices } = useDevices();

  useEffect(() => {
    // The 'supportsFacingMode' variable determines if this component is rendered
    // If 'facingMode' exists, we will set supportsFacingMode to true.
    // However, if facingMode is ever undefined again (when the user unpublishes video), we
    // won't set 'supportsFacingMode' to false. This prevents the icon from briefly
    // disappearing when the user switches their front/rear camera.
    const currentFacingMode = mediaStreamTrack ? mediaStreamTrack.getSettings().facingMode : null;
    if (currentFacingMode && supportsFacingMode === false) {
      setSupportsFacingMode(true);
    }
  }, [mediaStreamTrack, supportsFacingMode]);

  const toggleFacingMode = useCallback(() => {
    const newFacingMode = mediaStreamTrack ? (mediaStreamTrack.getSettings().facingMode === 'user' ? 'environment' : 'user') : null;
    if (videoTrack) {
      videoTrack.restart({
        ...videoConfig,
        facingMode: newFacingMode,
      });
    }
  }, [mediaStreamTrack, videoTrack]);

  return {
    flipCameraDisabled: !videoTrack,
    toggleFacingMode,
    flipCameraSupported: supportsFacingMode && videoInputDevices.length > 1,
  };
}
