import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import room from "./room/reducer";
import controller from "./controller/reducer";
import fishbowl from "./fishbowl/reducer";
import users from "./users/reducer";
import test from "./test/reducer";
import checkin from "./checkin/reducer";
import registration from "./registration/reducer";
import organizer from "./organizer/reducer";
import auth from "./auth/reducer";
import preview from "./preview/reducer";
import home from "./home/reducer";
import twilio from "./twilio/reducer";
import event from "./event/reducer";
import system from "./system/reducer";
import webinar from "./webinar/reducer";
import thunk from "redux-thunk";
import LogRocket from "logrocket";
import { createLogger } from "redux-logger";
import * as Sentry from "@sentry/react";
import config from "../utils/config";

const reducers = combineReducers({
    room,
    controller,
    fishbowl,
    users,
    test,
    checkin,
    registration,
    organizer,
    auth,
    preview,
    home,
    twilio,
    event,
    system,
    webinar
});

const logger = createLogger({collapsed: true});
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

let middleware = [thunk, LogRocket.reduxMiddleware()];
if (config.SHOW_REDUX_LOGGER) {
    middleware.push(logger);
}

const store = createStore(
    reducers,
    {},
    compose(
    applyMiddleware(...middleware),
    sentryReduxEnhancer,
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : f => f
    )
);

export default store
