import React from 'react'
import {connect} from 'react-redux'
import styled from 'styled-components';
import { withRouter } from 'react-router-dom'
import { PopupContainer, PopupWrapper, Popup, AcceptButton, DeclineButton, PopupTimerWrapper } from "./styled";
import sound from "../../assets/careernumbers_next_box__3_.mp3";
import Timer from "./Timer";
import socketModule from "../../utils/socketModule";
import {getDataOptions, is2WayCall, is3WayCall} from "../../utils/helpers";
import {setSelectedNextSessionVariantAction, showExtendCallPopupAction} from 'src/store/controller/actions'
import { logging } from "../../store/controller/actions";

const StyledPopupWrapper = styled(PopupTimerWrapper)`
  margin-left: 20px;
  width: 20px;
  font-size: 11px;
  color: #888888;
`;

class ExtendCall extends React.Component {

    state = {
        initiator: ''
    };

    _audio = new Audio(sound);

    async componentDidMount() {
        const { otherUser, otherUserB, otherUserChoice, otherUserBChoice } = this.props;
        this._audio.play();
        this.setState({
            initiator: otherUserChoice === 'extend' ? otherUser.firstName : otherUserBChoice === 'extend' ?  otherUserB.firstName : ''
        })
    }

    isPartnerNotConnected = () => {
        const { currentSession: { numberUsers }, partnerConnected, otherUser, otherUserB } = this.props;
        return is2WayCall(numberUsers, otherUser, otherUserB) && !partnerConnected;
    };

    isVariantAvailable = (variant) => {
        return !(this.props.currentView === 'video' && this.isPartnerNotConnected() && variant === 'extend');

    };

    handleVariantChanging = (variant) => {
        if (!this.isVariantAvailable(variant)) return;
        this.props.setSelectedNextSessionVariantAction(variant);
        socketModule.socket.emit('extend_call_choice', {
            eventId: this.props.currentEvent.eventId,
            roomNumber: this.props.currentSession.roomNumber,
            variant: variant,
            threeWayCall: is3WayCall(this.props.otherUser, this.props.otherUserB),
        });
    };

    extendCall = () => {
        this.props.logging({message: `Clicked <b>Extend</b> button.`});
        this.handleVariantChanging('extend');
    };

    pass = () => {
        this.props.logging({message: `Clicked <b>Pass</b> button.`});
        this.handleVariantChanging('pass');
        setTimeout(() => {
            this.props.showExtendCallPopupAction(false);
        }, 5 * 1000)
    };

    getExtendCallPopupText() {
        const {
            currentEvent: {minPerSession},
            otherUser,
            otherUserB,
            myChoice,
            otherUserChoice,
            otherUserBChoice,
            extendCallDirectMode,
            extendCallByMinutes,
        } = this.props;

        const other_user_name = otherUser.inRoom ? otherUser.firstName : otherUserB.inRoom ? otherUserB.firstName : '';
        const other_userB_name = (otherUser.inRoom && otherUserB.inRoom) ? otherUserB.firstName : '';

        if (other_userB_name === '') {
            if (otherUserChoice === 'extend' && myChoice === 'extend') {
                return `Your conversation has been extended ${minPerSession} minutes.`;
            }
            if (otherUserChoice === 'pass' && myChoice === 'extend') {
                return `${other_user_name} passed.`;
            }
            if (myChoice === 'pass') {
                return 'Okay. Thanks!'
            }
            return (
                <>
                    <span>Waiting for</span>
                    <span className={'no-translate'}>&nbsp;{other_user_name}&nbsp;</span>
                    <span>to respond.</span>
                </>
            )

        } else {
            if (extendCallDirectMode) {
                return `Your conversation has been extended ${extendCallByMinutes} minutes.`
            }
            if (!otherUserChoice && !otherUserBChoice) {
                return `Waiting for ${other_user_name} or ${other_userB_name} to respond.`;
            }
            if (
                (otherUserChoice === 'extend' && myChoice === 'extend') ||
                (otherUserBChoice === 'extend' && myChoice === 'extend')
            ) {
                return `Your conversation has been extended ${extendCallByMinutes ? extendCallByMinutes : minPerSession} minutes.`
            }
            if (myChoice === 'pass') {
                return 'Okay. Thanks!'
            }

            if (otherUserChoice === 'pass' && otherUserBChoice === 'pass') {
                return `${other_user_name} and ${other_userB_name} passed`
            }
            return `Waiting for ${other_user_name} or ${other_userB_name} to respond.`;
        }
    }

    render() {
        const { currentEvent,myChoice } = this.props;
        return (
            <PopupContainer>
                <PopupWrapper>
                    <Popup>
                        {!myChoice ?
                            <>
                                { this.state.initiator }: Extend Meet {currentEvent.minPerSession}m?
                                <StyledPopupWrapper>
                                    <Timer seconds="30" onTimeEnd={this.pass} />s
                                </StyledPopupWrapper>
                                <AcceptButton marginLeft={'20px'}
                                              data-trackable={
                                                  getDataOptions({
                                                      view: 'ExtendCall',
                                                      type: 'button',
                                                      label: 'Extend'
                                                  })
                                              }
                                              onClick={this.extendCall}>Extend</AcceptButton>
                                <DeclineButton
                                    data-trackable={
                                        getDataOptions({
                                            view: 'ExtendCall',
                                            type: 'button',
                                            label: 'Pass'
                                        })
                                    }
                                    onClick={this.pass}>Pass</DeclineButton>
                            </> :
                            <>
                                {this.getExtendCallPopupText()}
                            </>
                        }
                    </Popup>
                </PopupWrapper>
            </PopupContainer>
        );
    }
}

export default withRouter(
    connect(
        store => ({
            currentEvent: store.controller.currentEvent,
            currentSession: store.controller.currentSession,
            extendCallDirectMode: store.controller.extendCallDirectMode,
            extendCallByMinutes: store.controller.extendCallByMinutes,

            myChoice: store.room.myChoice,
            otherUserChoice: store.room.otherUserChoice,
            otherUserBChoice: store.room.otherUserBChoice,

            currentUser: store.users.currentUser,
            otherUser: store.users.otherUser,
            otherUserB: store.users.otherUserB,
        }),
        {
            setSelectedNextSessionVariantAction,
            showExtendCallPopupAction,
            logging
        })(ExtendCall)
)
