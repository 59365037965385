import React from "react";
import styled from "styled-components";
import FooterControls from "./FooterControls";
import { connect } from "react-redux";
import CallController from "./CallController";
import { isMobile } from "../../utils";

export const footerHeight = 73;

const FooterContainer = styled.div`
  position: fixed;
  bottom: ${pr => pr.screenSharing ? "60px" : "20px"};
  left: 50%;
  z-index: 105;
  display: flex;
`;

const FooterControlsBlock = styled.div`
  transform: translateX(-50%);
  border-radius: 3px;
  background-color: rgba(0,0,0,0.5);
  border-color: #080808;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Logo = styled.a`
  display: block;
  width: 200px;
  height: 40px;
  
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const Footer = ({ joinIsClicked, connected, showControls, videoDevice }) => {
    if (!(joinIsClicked && connected && showControls)) return null;

    return (
        <FooterContainer screenSharing={videoDevice === "screen"}>
            <FooterControlsBlock>
                <FooterControls/>
            </FooterControlsBlock>
            {!isMobile &&
                <CallController/>
            }
        </FooterContainer>
    );
};

export default connect(
    (store) => {
        return {
            joinIsClicked: store.room.joinIsClicked,
            connected: store.room.connected,
            showControls: store.room.showControls,
            videoDevice: store.controller.videoDevice,
        };
    }
)(Footer);
