import { useEffect, useState } from 'react';
import store from "../../../../store";
import { switchFishbowlViewAction } from "../../../../store/controller/actions";
import { toggleFooterControlsVisibilityAction } from "../../../../store/room/actions";
import fishbowlTypes from "../../../Popups/FishbowlViewTypes";

export default function useParticipantIsReconnecting(participant) {
  const [isReconnecting, setIsReconnecting] = useState(false);

  useEffect(() => {
    let timeout = null;

    const handleReconnecting = () => {
      console.info('[Hooks] participant.on(\'reconnecting\', handleReconnecting);');
      store.dispatch(toggleFooterControlsVisibilityAction(false));

      const currentUser = store.getState().users.currentUser;
      const isCurrentUser = participant.identity === `${currentUser.firstName} ${currentUser.lastName} | ${currentUser.userId}`;
      const isThreeWayCall = !!store.getState().users.otherUserB.userId;

      if (!timeout) {
        timeout = setTimeout(() => {
          if (isCurrentUser) {
            window.location.reload();
          } else if (!isThreeWayCall) {
            store.dispatch(switchFishbowlViewAction(fishbowlTypes.VIDEO_DESTROYED));
          }
        }, 20 * 1000);
      }

      return setIsReconnecting(true);
    };

    const handleReconnected = () => {
      console.info('[Hooks] participant.on(\'reconnected\', handleReconnected);');

      //roomController republish() -> initializePublisher()
      store.dispatch(toggleFooterControlsVisibilityAction(true));
      if (timeout) clearTimeout(timeout);
      return setIsReconnecting(false);
    };

    handleReconnected(); // Reset state when there is a new participant

    participant.on('reconnecting', handleReconnecting);
    participant.on('reconnected', handleReconnected);
    return () => {
      participant.off('reconnecting', handleReconnecting);
      participant.off('reconnected', handleReconnected);
    };
  }, [participant]);

  return isReconnecting;
}
