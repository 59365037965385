import DetectRTC from "detectrtc";
import socketModule from "../../utils/socketModule";
import { getInstanceAxios } from "../../utils/helpers";
import config from "../../utils/config";
import types from "../constans";
import { errorReasons } from "../../utils/constants";
import * as Sentry from "@sentry/react";

import {
    clearRoomInitialParams,
    endCallAction,
    hideJoinBlockAction,
    partnerConnectedAction,
    setIsVisitorAloneAction
} from "../room/actions";
import {
    setAccessGrantedErrorBlockVisibilityAction,
    setDisconnectedAction,
    setErrorInfoBlockVisibleAction,
    setInfoBlockVisibleAction,
    setMaxMembersBlockVisibilityAction,
    switchFishbowlViewAction
} from "../controller/actions";
import fishbowlTypes from "../../components/Popups/FishbowlViewTypes";

export const setAudioOutputId = (activeSinkId) => (dispatch) => {
    return dispatch({
        type: types.SET_TWILIO_AUDIO_OUTPUT_ID,
        activeSinkId
    });
};

export const setRoomInstance = (roomInstance) => (dispatch) => {
    return dispatch({
        type: types.SET_TWILIO_ROOM_INSTANCE,
        roomInstance
    });
};

export const setStopAllTracks = (value) => dispatch => {
    return dispatch({
        type: types.SET_TWILIO_STOP_ALL_TRACK,
        value
    });
};

export const setVideoTrackAction = (videoTrack) => (dispatch) => {
    return dispatch({
        type: types.SET_TWILIO_VIDEO_TRACK,
        videoTrack
    });
};

export const setAudioTrackAction = (audioTrack) => (dispatch) => {
    return dispatch({
        type: types.SET_TWILIO_AUDIO_TRACK,
        audioTrack
    });
};

export const dispatchSetting = (action) => (dispatch) => {
    dispatch({
        type: types.SET_TWILIO_SETTINGS,
        action
    });
    return action;
};

export const dispatchError = (error) => (dispatch) => {
    return dispatch(setError(error));
};

export const setError = (error) => async (dispatch) => {
    console.error(error);

    if (error.code == 53405) {
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem('twilio-53405-error-refreshed') || 'false'
        );
        if (!hasRefreshed) {
            window.sessionStorage.setItem('twilio-53405-error-refreshed', 'true');
            window.location.reload();
        } else {
            dispatch(showErrorInfoBlock(errorReasons["REASON_NETWORK_DISCONNECTED"]));
            dispatch(partnerConnectedAction(false));
            dispatch(switchFishbowlViewAction(fishbowlTypes.VIDEO_DESTROYED));
        }
    }

    dispatch(sendTwilioStatistics({
        method: 'FE setError',
        message: error.message,
        code: error.code,
        name: error.name
    }));
    return {
        type: types.SET_TWILIO_ERROR,
            error
    }
};

export const sendTwilioStatistics = (data, type) => (dispatch, getState) => {
    const {
        controller: {
            currentEvent: {eventId},
            currentSession: {roomNumber}
        },
        users: {currentUser: {userId}}
    } = getState();

    const detectRTC = window.detectRtcData || DetectRTC;
    const browser = detectRTC ? detectRTC.browser.name : 'n/d';
    const browserVersion = detectRTC ? detectRTC.browser.version : 'n/d';
    const osName = detectRTC ? detectRTC.osName : 'n/d';
    const osVersion = detectRTC ? detectRTC.osVersion : 'n/d';

    const dataObject = {
        eventId, roomNumber, userId, data,
        browser: `${browser} (${browserVersion})`,
        os: `${osName} (${osVersion})`,
        type: type ? type : 'FE error',
    };

    socketModule.socket.emit('twilio.statistics', dataObject);

    if (dataObject.type === 'FE Logger' || dataObject.type === 'FE error') {
        const reason = data.message || data.reason || type || 'Unknown reason';
        Sentry.captureException(new Error(reason), scope => {
            scope.setTag("scope", "Twilio");
            scope.setContext("extra-data", dataObject);
        });
    }
}

export const setIsFetching = (isFetching) => ({
    type: types.SET_TWILIO_IS_FETCHING,
    isFetching
});

export const dispatchRoomType = (roomType) => (dispatch) => {
    return dispatch(setRoomType(roomType));
};

export const setRoomType = (roomType) => ({
    type: types.SET_TWILIO_ROOM_TYPE,
    roomType
});

export const setRoomName = (roomName) => ({
    type: types.SET_TWILIO_ROOM_NAME,
    roomName
});

export const getToken = (room_name) => async (dispatch, getState) => {
    dispatch(setIsFetching(true));
    const { controller: { currentEvent: { eventId } }} = getState();
    //
    // if (roomInstance) await roomInstance.disconnect();

    const endpoint = `${config.NODE_API_URL}/twilio/tokens`;
    const response = await getInstanceAxios().post(endpoint, {
        room_name,
        create_conversation: true,
        event_id: eventId
    });

    if (response && response.data && response.data.status === 200) {
        dispatch(setRoomType(response.data.room_type));
        dispatch(setRoomName(room_name));
        dispatch(clearRoomInitialParams());
        dispatch({type: types.INIT_ROOM_CONTROLLER_SUCCESS});
        setTimeout(()=>dispatch(setIsFetching(false)), 5000);
        return response.data;
    } else {
        const recordingError = new Error(
            response.data.error ? response.data.error.message : "There was an error updating recording rules"
        );
        recordingError.code = response.data.error ? response.data.error.code : null;
        dispatch(endCallAction());
        setTimeout(()=>dispatch(setIsFetching(false)), 5000);
        return dispatch(setError(recordingError));
    }
};

export const updateRecordingRules = (room_sid, rules) => async (dispatch) => {
    dispatch(setIsFetching(true));
    const endpoint = `${config.NODE_API_URL}/twilio/recordingrules`;
    const { data } = await getInstanceAxios().post(endpoint, {
        room_sid,
        rules
    });

    if (data && data.status === 200) {
        dispatch(setIsFetching(false));
        return data;
    } else {
        dispatch(setIsFetching(false));
        const recordingError = new Error(
            data.error ? data.error.message : "There was an error updating recording rules"
        );
        recordingError.code = data.error ? data.error.code : null;
        return dispatch(setError(recordingError));
    }
};

export const showErrorInfoBlock = (reason) => (dispatch) => {
    dispatch(setDisconnectedAction(false, {}));
    dispatch(setIsVisitorAloneAction(false));
    dispatch(setMaxMembersBlockVisibilityAction(false));

    const reasons = {
        common: false,
        accessDenied: false,
        noConnection: false,
        networkError: false
    };

    switch (reason) {
        case errorReasons['REASON_ACCESS_DENIED'] :
            reasons.accessDenied = true;
            break;
        case errorReasons['REASON_NO_CONNECTION'] :
            reasons.noConnection = true;
            break;
        case errorReasons['REASON_DISCONNECTED'] :
        case errorReasons['REASON_NETWORK_DISCONNECTED'] :
            reasons.networkError = true;
            break;
       case errorReasons['REASON_NETWORK_UNSTABLE'] :
            reasons.networkWarning = true;
            break;
       case errorReasons["REASON_RECONNECTING"] :
            reasons.reconnectingWarning = true;
            break;
        default :
            reasons.common = true;
            break;
    }

    dispatch(hideJoinBlockAction());
    dispatch(setErrorInfoBlockVisibleAction(true, reasons));
};

export const showAccessGrantedErrorBlock = (reason) => (dispatch) => {
    dispatch(setDisconnectedAction(false, {}));
    dispatch(setIsVisitorAloneAction(false));
    dispatch(setMaxMembersBlockVisibilityAction(false));
    dispatch(hideJoinBlockAction());

    const reasons = {
        mediaAccessDenied: false,
        webcamBlocked: false
    };

    switch (reason) {
        case errorReasons['REASON_ACCESS_DENIED'] :
            reasons.mediaAccessDenied = true;
            break;
        case errorReasons['REASON_WEBCAM_IS_BLOCKED'] :
            reasons.webcamBlocked = true;
            break;
        default :
            return;
    }

    dispatch(sendTwilioStatistics(
        {
            reason: reasons,
            method: "FE showErrorInfoBlock",
        }, 'Message'
    ));

    dispatch(setAccessGrantedErrorBlockVisibilityAction(true, reasons));
};

export const switchInfoMessageByReason = (reason) => (dispatch) => {
    dispatch(setInfoBlockVisibleAction(true, {
        conLow: reason === errorReasons['REASON_QUALITY_OTHER'],
        conLowOther: reason === errorReasons['REASON_QUALITY_ME'],
        browserReloaded: false
    }));
};

export const clearInfoBlock = () => (dispatch) => {
    dispatch(setInfoBlockVisibleAction(false, {
        conLow: false,
        conLowOther: false,
        browserReloaded: false
    }));
};

export const clearErrorInfoBlock = () => (dispatch) => {
    const reasons = {
        accessDenied: false,
        common: false,
        networkError: false,
        noConnection: false
    };

    dispatch(setErrorInfoBlockVisibleAction(false, reasons));
};
