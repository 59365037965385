import React from "react";
import Video from "twilio-video";
import { connect } from "react-redux";
import styled from "styled-components";
import Button from "src/components/Common/GreenButton";


const DialogWrapper = styled.div`
    position: absolute;
    top: calc(-70vh);
    left: -70px;
    width: 400px;
`;

const Dialog = styled.div`
    width: calc(100% - 64px);
    max-width: 444px;
    display: flex;
    max-height: calc(100% - 64px);
    flex-direction: column;
    margin: 32px;
    position: relative;
    overflow-y: auto;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
    border-radius: 4px;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
`;

const Divider = styled.div`
  height: 1px;
  background: rgba(0, 0, 0, 0.12);
`;

const DialogTitle = styled.div`
    flex: 0 0 auto;
    margin: 0;
    padding: 16px 24px;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
`;

const DialogContentText = styled.p`
    color: rgba(0, 0, 0, 0.54);
`;

const DialogContent = styled.div`
    flex: 1 1 auto;
    padding: 8px 24px;
`;

const DialogActions = styled.div`
    flex: 0 0 auto;
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: flex-end;
`;


const AboutDialog = ({ open, onClose, roomType }) => {
    if (!open) return null;
    return (
        <DialogWrapper>
            <Dialog>
                <DialogTitle>About</DialogTitle>
                <Divider/>
                <DialogContent>
                    <DialogContentText>Browser supported: {String(Video.isSupported)}</DialogContentText>
                    <DialogContentText>SDK Version: {Video.version}</DialogContentText>
                    {roomType && <DialogContentText>Room Type: {roomType}</DialogContentText>}
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button onClick={onClose} style={{ padding: 8 }}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </DialogWrapper>
    );
};

const mapStateToProps = (store) => ({
    roomType: store.twilio.roomType
});

export default connect(mapStateToProps)(AboutDialog);
