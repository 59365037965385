import React from 'react';
import Video from 'twilio-video';
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2.5em;
`;
const Paper = styled.div`
  padding: 1em;
`;
const Heading = styled.h4`
  margin-bottom: 0.4em
`;

export default function UnsupportedBrowserWarning({ children }) {
  // if (!Video.isSupported) {
  //   return (
  //     <div>
  //         <Container>
  //           <Paper>
  //             <Heading>
  //               Browser or context not supported
  //             </Heading>
  //             <div>
  //               Please open this application in one of the{' '}
  //               <a
  //                 href="https://www.twilio.com/docs/video/javascript#supported-browsers"
  //                 target="_blank"
  //                 rel="noopener"
  //               >
  //                 supported browsers
  //               </a>
  //               .
  //               <br />
  //               If you are using a supported browser, please ensure that this app is served over a{' '}
  //               <a
  //                 href="https://developer.mozilla.org/en-US/docs/Web/Security/Secure_Contexts"
  //                 target="_blank"
  //                 rel="noopener"
  //               >
  //                 secure context
  //               </a>{' '}
  //               (e.g. https or localhost).
  //             </div>
  //           </Paper>
  //         </Container>
  //     </div>
  //   );
  // }

  return children;
}
