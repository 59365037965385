import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { setDeviceChangingAction } from "src/store/controller/actions";

import AudioController from "./AudioController";
import VideoController from "./VideoController";
import ChatController from "./ChatController";
import CallMobileController from './CallMobileController';
import CurrentVisibleControl from "./CurrentVisibleControl";
import { Container } from "./styled";
import Menu from "../MenuBar/Menu/Menu";
import { isMobile } from "../../utils";

const FooterControlsContainer = styled(Container)`
  align-items: center;
`;

const FooterControls = (props) => {
    return (
        <>
            <CurrentVisibleControl/>
            <FooterControlsContainer>
                <VideoController/>
                <AudioController/>
                <ChatController/>
                <Menu/>
                {isMobile &&
                    <CallMobileController/>
                }
            </FooterControlsContainer>
        </>
    );
};

export default connect(
    (state) => ({
        videoDevice: state.controller.videoDevice
    }),
    {
        setDeviceChangingAction
    }
)(FooterControls);
