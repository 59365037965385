import { useEffect, useState } from "react";
import useVideoContext from "../useVideoContext/useVideoContext";
import store from "../../../../store";
import { memberJoinConferenceAction, setVideoConnectedAction } from "../../../../store/room/actions";
import { setErrorInfoBlockVisibleAction } from "../../../../store/controller/actions";

export default function useRoomState() {
  const { room } = useVideoContext();
  const [state, setState] = useState('disconnected');

  useEffect(() => {
    if (room) {
      const setRoomState = (e) => {
        console.log('setRoomState', e);

        if (room.state && state !== room.state) {
          const {videoConnected} = store.getState().controller.currentSession;
          if (room.state === 'connected') {
            if (!videoConnected) {
              store.dispatch(setVideoConnectedAction(true));
              store.dispatch(memberJoinConferenceAction());
            }
          } else {
            if (videoConnected) {
              store.dispatch(setVideoConnectedAction(false));
            }
          }
        }

        setState(room.state);
      };

      setRoomState();
      room
        .on('disconnected', (e)=>{console.info('[Hooks] room.on(\'disconnected\''); setRoomState(e)})
        .on('reconnected', (e)=>{
          console.info('[Hooks] room.on(\'reconnected\'');
          setRoomState(e);
          store.dispatch(setErrorInfoBlockVisibleAction(false));
        })
        .on('reconnecting', (e)=>{console.info('[Hooks] room.on(\'reconnecting\''); setRoomState(e)});
      return () => {
        room
          .off('disconnected', setRoomState)
          .off('reconnected', setRoomState)
          .off('reconnecting', setRoomState);
      };
    }
  }, [room]);

  return state;
}
