import { useEffect, useState } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';

export default function useIsRecording() {
  const { room } = useVideoContext();
  const [isRecording, setIsRecording] = useState(Boolean(room ? room.isRecording : null));

  useEffect(() => {
    if (room) {
      setIsRecording(room.isRecording);

      const handleRecordingStarted = () => {
        console.info('[Hooks] room.on(\'recordingStarted\', handleRecordingStarted);');
        setIsRecording(true);
      };
      const handleRecordingStopped = () => {
        console.info('[Hooks] room.on(\'recordingStopped\', handleRecordingStopped);');
        setIsRecording(false);
      };

      room.on('recordingStarted', handleRecordingStarted);
      room.on('recordingStopped', handleRecordingStopped);

      return () => {
        room.off('recordingStarted', handleRecordingStarted);
        room.off('recordingStopped', handleRecordingStopped);
      };
    }
  }, [room]);

  return isRecording;
}
