import React from 'react';
import Styled from './styles';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileDownload} from "@fortawesome/free-solid-svg-icons";


const MediaMessage = ({media}) => {
    const handleClick = () => {
        media.getContentTemporaryUrl().then(url => {
            const anchorEl = document.createElement('a');

            anchorEl.href = url;
            anchorEl.target = '_blank';
            anchorEl.rel = 'noopener';

            // setTimeout is needed in order to open files in iOS Safari.
            setTimeout(() => {
                anchorEl.click();
            });
        });
    };

    const formatFileSize = (bytes, suffixIndex = 0) => {
        const suffixes = ['bytes', 'KB', 'MB', 'GB'];
        if (bytes < 1000) return +bytes.toFixed(2) + ' ' + suffixes[suffixIndex];
        return formatFileSize(bytes / 1024, suffixIndex + 1);
    };

    return (
        <Styled.MessageContainer onClick={handleClick}>
            <Styled.IconContainer>
                <FontAwesomeIcon icon={faFileDownload} size="2x"/>
            </Styled.IconContainer>
            <Styled.MediaInfo>
                <Styled.Filename>{media.filename}</Styled.Filename>
                <Styled.Size>{formatFileSize(media.size)} - Click to open</Styled.Size>
            </Styled.MediaInfo>
        </Styled.MessageContainer>
    )
};

export default MediaMessage;