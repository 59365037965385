import React, {useEffect} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import DisconnectedInfo from '../components/InfoBlocs/DisconnectedInfo';
import {joinIsClickedAction} from '../store/room/actions';

const Caption = styled.h2`
  margin-top: 0;
  margin-bottom: 30px;
  color: #C0C0C0;
  font-size: 14px;
  font-weight: 400;
  text-shadow: 2px 1px 3px rgba(0, 0, 0, 0.5);
`;

export const Loader = styled.div`
    border: 3px solid #f3f3f3;
    border-top: 3px solid transparent;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
`;

const JoinBlock = (props) => {
    const {
        recorded, disconnected, disconnectedReasons, publisherIsReady, joinIsClickedAction,
        surveySuccessfullyPosted, endCallClicked, sessionConnected, isReady, isPublishProblems
    } = props;

    useEffect(() => {
        if (surveySuccessfullyPosted && isReady && !endCallClicked && !sessionConnected && publisherIsReady) {
            setTimeout(() => {joinIsClickedAction()}, 100)
        }
    }, [surveySuccessfullyPosted, isReady, endCallClicked, sessionConnected, publisherIsReady, joinIsClickedAction]);

    if (props.hideJoinBy3rdParty || props.showNextPartnerBioPopup || (!endCallClicked && !disconnected)) return null;

    return (
        <>
        {recorded && (
            <Caption>
                Your call may be recorded to improve our product
            </Caption>
        )}
        {disconnected && ( <DisconnectedInfo reasons={disconnectedReasons} /> )}
            {isPublishProblems &&  <DisconnectedInfo reasons={{publishProblems: 'Oops, please refresh the page'}} />}
        </>
    );
};

export default connect(
    (store) => ({
        disconnected: store.controller.disconnected,
        disconnectedReasons: store.controller.disconnectedReasons,
        hideJoinBy3rdParty: store.controller.hideJoinBy3rdParty,
        showNextPartnerBioPopup: store.controller.showNextPartnerBioPopup,
        surveySuccessfullyPosted: store.controller.surveySuccessfullyPosted,
        isReady: store.controller.isReady,
        recorded: store.room.needRecord,
        joinIsClicked: store.room.joinIsClicked,
        connected: store.room.connected,
        publisherIsReady: store.room.publisherIsReady,
        isPublishProblems: store.controller.isPublishProblems,
        endCallClicked: store.room.endCallClicked,
        sessionConnected: store.room.connected,
        fishbowlView: store.controller.fishbowlView,
        showRightFlyInPopup: store.controller.showRightFlyInPopup
    }),
    {
        joinIsClickedAction
    }
)(JoinBlock);
