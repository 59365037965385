import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { ChatProvider } from "./components/ChatProvider";
import { VideoProvider } from "./components/VideoProvider";
import useConnectionOptions from "./utils/useConnectionOptions/useConnectionOptions";
import { dispatchError } from "../../store/twilio/actions";
import { setDirectModeAction } from "../../store/controller/actions";

import TwilioVideoApp from "./TwilioVideoApp";
import ErrorDialog from "./components/ErrorDialog/ErrorDialog";
import UnsupportedBrowserWarning from "./components/UnsupportedBrowserWarning/UnsupportedBrowserWarning";
import { identifyLogRocket } from "../../utils/helpers";


const TwilioVideo = ({ currentUser, setDirectModeAction, dispatchError, error, ...props }) => {
    const roomRoute = _.get(props, "location.pathname", "").search("/room/") !== -1;

    const [identifiedLogRocket, setIdentifiedLogRocket] = useState(false);

    useEffect(() => {
        if (roomRoute) {
            setDirectModeAction(true);
        }
    }, [roomRoute]);

    useEffect(() => {
        if (roomRoute) {
            if (!identifiedLogRocket && currentUser.userId) {
                const urlRoomName = window.location.href
                    .replace(/#|!/g, "")
                    .match(/[^\/]+$/)[0];
                setIdentifiedLogRocket(true);
                identifyLogRocket(currentUser, urlRoomName);
            }
        }
    }, [currentUser, identifiedLogRocket]);

    const connectionOptions = useConnectionOptions();

    return (
        <UnsupportedBrowserWarning>
            <VideoProvider options={connectionOptions} onError={dispatchError}>
                <ErrorDialog dismissError={() => dispatchError(null)} error={error}/>
                <ChatProvider>
                    <TwilioVideoApp/>
                </ChatProvider>
            </VideoProvider>
        </UnsupportedBrowserWarning>
    );
};

const mapStateToProps = (store) => ({
    error: store.twilio.error,
    currentUser: store.users.currentUser,
});

const mapDispatchToProps = {
    dispatchError,
    setDirectModeAction
};

export default connect(mapStateToProps, mapDispatchToProps)(TwilioVideo);

