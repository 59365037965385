import { useEffect, useRef } from 'react';
import {connect} from "react-redux";

const AudioTrack = ({ track, activeSinkId }) => {
  const audioEl = useRef();

  useEffect(() => {
    audioEl.current = track.attach();
    audioEl.current.setAttribute('data-cy-audio-track-name', track.name);
    document.body.appendChild(audioEl.current);
    return () =>
        track.detach().forEach(el => {
          el.remove();
          el.srcObject = null;
        });
  }, [track]);

  useEffect(() => {
    if (audioEl && audioEl.current && audioEl.current.setSinkId && activeSinkId) {
      audioEl.current.setSinkId(activeSinkId);
    }

  }, [activeSinkId]);

  return null;
}

const mapStateToProps = (store) => ({
  activeSinkId: store.twilio.activeSinkId,
});

export default connect(mapStateToProps)(AudioTrack);

