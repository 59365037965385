import { useEffect, useState } from "react";
import useDominantSpeaker from "../useDominantSpeaker/useDominantSpeaker";
import useVideoContext from "../useVideoContext/useVideoContext";
import store from "../../../../store";
import {
  memberLeaveConferenceAction,
  partnerConnectedAction,
  setIsVisitorAloneAction,
  setVideoConnectedAction
} from "../../../../store/room/actions";
import { setErrorInfoBlockVisibleAction, showFishbowlPopupAction } from "../../../../store/controller/actions";

export default function useParticipants(callDropFishbowl = false) {
  const { room } = useVideoContext();
  const dominantSpeaker = useDominantSpeaker();
  const [participants, setParticipants] = useState(
      Array.from(room && room.participants ? (room.participants.values() || []) : [])
          .filter(({identity}) => !identity.includes('-mux-'))
  );

  // When the dominant speaker changes, they are moved to the front of the participants array.
  // This means that the most recent dominant speakers will always be near the top of the
  // ParticipantStrip component.
  useEffect(() => {
    if (dominantSpeaker) {
      setParticipants(prevParticipants => [
        dominantSpeaker,
        ...prevParticipants.filter(participant => participant !== dominantSpeaker),
      ]);
    }
  }, [dominantSpeaker]);

  useEffect(() => {
    if (participants.length && callDropFishbowl) {
      store.dispatch(partnerConnectedAction(true));
      store.dispatch(setIsVisitorAloneAction(false));
      store.dispatch(showFishbowlPopupAction(false));
      const {videoConnected} = store.getState().controller.currentSession;

      if (!videoConnected) store.dispatch(setVideoConnectedAction(true));
    }
  }, [participants]);

  useEffect(() => {
    if (room) {
      const participantConnected = (participant) => {
        if (participant.identity.includes('-mux-')) return;

        console.info('[Hooks] room.on(\'participantConnected\') - Participant: ' + participant.identity + ' has connected', {participant});

        const {controller: {errorInfoBlockReasons, errorInfoBlock}} = store.getState();
        if (errorInfoBlock) {
          store.dispatch(setErrorInfoBlockVisibleAction(false, {
            ...errorInfoBlockReasons, reconnectingWarning: false
          }));
        }

        return setParticipants(prevParticipants => [...prevParticipants, participant]);
      };

      const participantDisconnected = (participant) => {
        console.info('[Hooks] room.on(\'participantDisconnected\') - Participant: ' + participant.identity + ' has disconnected', {participant});

        // stop listening for this participant
        participant.removeAllListeners();

        store.dispatch(memberLeaveConferenceAction());
        const numberUsers = parseInt(store.getState().controller.currentSession.numberUsers);
        const participants = Array.from(room && room.participants ? (room.participants.values() || []) : []);
        if (numberUsers === 2 || (numberUsers === 3 && !participants.length)) {
          store.dispatch(partnerConnectedAction(false));
        }

        return setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));
      };

      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      return () => {
        room.off('participantConnected', participantConnected);
        room.off('participantDisconnected', participantDisconnected);
      };
    }
  }, [room]);

  return participants;
}
